import { Chart, registerables } from 'chart.js'

import * as helpersTimeseries from '../timeseries/timeseries.js'
import { chartInstances } from './instances.js'

// import * as configVar from '../../config.js'
Chart.register(...registerables)

/*
  @params
    .chartContainerId
    .chartValues: { dates: [], values: [] }
*/
export function drawInventoryChart (params = {}) {
  // // console.log('drawInventoryChart', params)
  const ingredientName = params.ingredientName
  // const chartId = params.chartId
  const chartElementId = params.chartElementId

  params.dates = params.dates || {
    min: helpersTimeseries.getDayWithOffset(-14)
  }

  const config = {
    type: 'bar',
    options: {
      maintainAspectRatio: false, // Fill the container size
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          },
          stacked: true
        },
        y: {
          title: {
            display: true,
            text: params.unit
          },
          stacked: true,
          position: 'left'
        }
        // y2: {
        //   title: {
        //     display: true,
        //     text: 'days',
        //     color: colorByFlow.daysOfInventoryEstimate
        //   },
        //   ticks: {
        //     color: colorByFlow.daysOfInventoryEstimate
        //   },
        //   position: 'right',
        //   grid: {
        //     drawOnChartArea: false // only want the grid lines for one axis to show up
        //   }
        // }
      },
      plugins: {
        title: {
          display: true,
          text: [params.title, ingredientName].join(': ')
        },
        legend: {
          position: 'bottom'
        }
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      onClick: clickBehaviour
      // appContext: {
      //   metricsDB: params.metricsDB,
      //   timeframe: params.timeframe,
      //   chartParams: params
      // }
    }
  }

  const chartContainerId = chartElementId // 'c_' + chartId
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  let dateTimeframe = params.timeframe
  const firstIndex = helpersTimeseries.getFirstIndex(params)
  const lastIndex = helpersTimeseries.getLastIndex(params)

  dateTimeframe = dateTimeframe.slice(firstIndex, lastIndex)

  // Set the Chart to have such xAxis labels
  const chartData = {
    labels: dateTimeframe,
    datasets: []
  }

  params.datasets.forEach(function (oneDataset) {
    const dataValues = oneDataset.values.slice(firstIndex, lastIndex)
    const colors = colorByFlow[oneDataset.flowType] //  '#bed2f4'

    if (
      oneDataset.flowType === 'runningInventory' &&
      ingredientName === 'water'
    ) { return }

    let chartType
    const yAxisID = 'y'
    let fill = false
    const hidden = false
    if (oneDataset.flowType === 'runningInventory') {
      chartType = 'line'
    } else if (oneDataset.flowType === 'daysOfInventoryEstimate') {
      chartType = 'line'
      // yAxisID = 'y2'
      fill = 'origin'
      // hidden = true
    } else {
      chartType = 'bar'
    }
    chartData.datasets.push({
      label: oneDataset.flowType,
      data: dataValues,
      type: chartType,
      borderColor: colors,
      pointRadius: 1,
      backgroundColor: colors,
      order: 1,
      yAxisID: yAxisID,
      fill: fill,
      hidden: hidden
    })
  })

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}

const colorByFlow = {
  nursery: '#2E518B',
  breeding: '#3874D9',
  farming: '#BED2F4',
  receiving: '#B2E8D1',
  adjustments: '#F1CAC1',
  runningInventory: '#4285f4',
  // daysOfInventoryEstimate: '#D7FFEE'
  // daysOfInventoryEstimate: '#E0E0E0'
  daysOfInventoryEstimate: '#EAE3FE'
}

function clickBehaviour (clickEvent, element) {
  // console.log('clickBehaviour', clickEvent, element)
  // const clickedXindex = element[0].index
  // const chartInstance = clickEvent.chart
  // const appContext = chartInstance.config.options.appContext
  // const clickedLabel = clickEvent.chart.data.labels[clickedXindex]
  // // console.log(clickedLabel)
  // const chartParams = appContext.chartParams
  // const firstIndex = helpersTimeseries.getFirstIndex(chartParams)
  // const dailySeriesIndex = firstIndex + clickedXindex
  // const chartMetricName = chartParams.metricName
  // const metricDB = chartParams.metricsDB[chartMetricName]
  // const records = metricDB.records[dailySeriesIndex]
  // // console.log(dailySeriesIndex)
  // // console.log(records)
  // // Show the record in a modal, with link to iOS
  // const highlightElement = document.getElementById('recordHighlights')
  // highlightElement.innerHTML = '' // clean
  // const baseUrlToiOS = configVar.baseUrlToiOS
  // records.forEach(function (oneRecord) {
  //   const urlToRecord = [baseUrlToiOS, oneRecord.productionLine, oneRecord.workstationForm, 'edit', oneRecord._id].join('/')
  //   // // console.log(urlToRecord)
  //   const oneLinkElement = document.createElement('a')
  //   oneLinkElement.setAttribute('href', urlToRecord)
  //   oneLinkElement.setAttribute('target', '_blank')
  //   oneLinkElement.innerText = [oneRecord.productionLine, oneRecord.workstationForm, 'edit', oneRecord._id].join('/')
  //   highlightElement.append(oneLinkElement)
  // })
  // highlightElement.classList.remove('hide')
  // // Eg. deeplink:
  // process-tracker/attendance/edit/9b624f1b51bf2093064166cc1a1d94e8
}
