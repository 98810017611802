import * as configVar from '../../config.js'
import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'

import * as recordsSettings from './settings.js'

const DOMelementId = 'formRecords'

export function showFormRecords () {
  // console.log('--- showFormRecords')
  // console.log('recordsSettings', recordsSettings.layoutFilters)

  const numberDays = 30

  analyseUsers({
    formsByDay: recordsSettings.formsByDay,
    numberDays
  })

  const DOMelement = document.getElementById(DOMelementId)
  DOMelement.innerHTML = '' // Reset

  let dayTable = 0
  while (dayTable < numberDays) {
    showScheduleOfDay({
      formsByDay: recordsSettings.formsByDay,
      day: helpersTimeseries.getDayWithOffset(-dayTable)
    })
    dayTable++
  }
}

function analyseUsers (params) {
  const numberDays = params.numberDays
  const formsByDay = params.formsByDay

  const users = new Set()
  // Find all users of the period
  let dayTable = 0
  while (dayTable < numberDays) {
    const targetDay = helpersTimeseries.getDayWithOffset(-dayTable)
    const formsForDay = formsByDay.filter(function (oneDayRecords) {
      return oneDayRecords[0] === targetDay
    })
    // console.log('formsForDay', formsForDay)
    formsForDay[0][1].forEach(function (oneRecord) {
      users.add(oneRecord.user_name)
    })
    dayTable++
  }
  // console.log('users', users)

  // Identify unclassified users
  const usersArray = Array.from(users)

  const unclassifiedUsers = []
  // console.log('usersArray', usersArray)
  usersArray.forEach(function (oneUser) {
    const userName = oneUser.trim() // .toLowerCase()
    if (recordsSettings.usersList[userName]) return

    recordsSettings.usersList[userName] = ['unclassifiedUsers']
    unclassifiedUsers.push(userName)
  })
  // console.log('unclassifiedUsers', unclassifiedUsers)

  return usersArray
}

function isUserWithinFilter (oneUser) {
  let isUserPassing = false

  const userTags = recordsSettings.usersList[oneUser]
  userTags.forEach(function (oneTag) {
    if (recordsSettings.layoutFilters.has(oneTag)) isUserPassing = true
  })
  return isUserPassing
}

//
function showScheduleOfDay (params) {
  // console.log('showScheduleOfDay', params)

  const dayRecords = analyseDay({
    formsByDay: params.formsByDay,
    day: params.day
  })
  // // console.log('dayRecords', dayRecords)

  const hourInterval = 1
  const hoursRange = [6, 23]

  const users = []
  Object.keys(recordsSettings.usersList).forEach(function (oneUser) {
    const userName = oneUser.trim() // .toLowerCase()

    if (isUserWithinFilter(userName)) {
      users.push(userName)
    }
  })
  // // console.log('users', users)

  const DOMelement = document.getElementById(DOMelementId)
  if (!DOMelement) return

  const tableElement = document.createElement('div')
  tableElement.classList.add('tableLayout')

  const headerElement = tableHeader({
    users
  })
  tableElement.appendChild(headerElement)

  tableRows({
    tableElement,
    users,
    hourInterval,
    hoursRange,
    dayRecords
  })

  const tableHeading = document.createElement('h3')
  tableHeading.innerText = params.day
  DOMelement.appendChild(tableHeading)

  DOMelement.appendChild(tableElement)
}

//
function analyseDay (params) {
  // // console.log('analyseDay', params)
  const formsByDay = params.formsByDay
  const targetDay = params.day

  const formsForDay = formsByDay.filter(function (oneDayRecords) {
    return oneDayRecords[0] === targetDay
  })

  // // console.log('formsForDay', formsForDay)
  return formsForDay[0][1]
}

//
//
function tableHeader (params) {
  // // console.log('tableHeader', params)
  const users = params.users

  const rowElement = document.createElement('div')
  rowElement.classList.add('row')

  function leftColumn () {
    const oneLeftCell = document.createElement('div')
    oneLeftCell.classList.add('cell')
    oneLeftCell.classList.add('header')
    oneLeftCell.classList.add('small')

    rowElement.appendChild(oneLeftCell)
  }

  leftColumn()

  users.forEach(function (oneUser) {
    const oneCell = document.createElement('div')
    oneCell.classList.add('cell')
    oneCell.classList.add('header')
    oneCell.classList.add('wide')

    oneCell.innerText = oneUser

    rowElement.appendChild(oneCell)
  })
  return rowElement
}

function tableRows (params) {
  // // console.log('tableRows', params)
  const tableElement = params.tableElement
  const users = params.users
  const hourInterval = params.hourInterval
  const hoursRange = params.hoursRange
  const dayRecords = params.dayRecords

  let startingHour = hoursRange[0]

  while (startingHour <= hoursRange[1]) {
    const oneRowElement = tableRow({
      users,
      hourInterval,
      startingHour,
      dayRecords
    })
    tableElement.appendChild(oneRowElement)

    startingHour += hourInterval
  }
}

function tableRow (params) {
  const startingHour = params.startingHour
  // const hourInterval = params.hourInterval
  const dayRecords = params.dayRecords

  const rowElement = document.createElement('div')
  rowElement.classList.add('row')

  function leftColumn () {
    const oneLeftCell = document.createElement('div')
    oneLeftCell.classList.add('cell')
    oneLeftCell.classList.add('left')
    oneLeftCell.classList.add('small')

    oneLeftCell.innerText = startingHour

    rowElement.appendChild(oneLeftCell)
  }

  leftColumn()

  const users = params.users

  users.forEach(function (oneUser) {
    const oneCell = document.createElement('div')
    oneCell.classList.add('cell')
    oneCell.classList.add('wide')

    const cellContent = recordOfUserTime({
      dayRecords,
      startingHour,
      oneUser
    })

    oneCell.appendChild(cellContent)

    rowElement.appendChild(oneCell)
  })
  return rowElement
}

const productionLineMapping = {
  farming: '(F) ',
  breeding: '(B) ',
  'process-tracker': ''
}

function generateFormNameFormatted (params) {
  const productionLine = params.productionLine
  const workstationForm = params.workstationForm

  if (typeof productionLineMapping[productionLine] === 'string') {
    return productionLineMapping[productionLine] + workstationForm
  }

  return [productionLine, workstationForm].join('/')
}

function recordOfUserTime (params) {
  // // console.log('recordOfUserTime', params)

  const dayRecords = params.dayRecords
  const startingHour = params.startingHour
  const oneUser = params.oneUser

  const cellRecords = dayRecords.filter(function (oneRecord) {
    const isTargetHour =
      oneRecord.recordHour === ('' + startingHour).padStart(2, '0')
    const isTargetUser =
      oneRecord.user_name.trim().toLowerCase() === oneUser.trim().toLowerCase()
    return isTargetHour && isTargetUser
  })
  // // console.log('cellRecords', cellRecords)

  const baseUrlToiOS = configVar.baseUrlToiOS

  const cellDiv = document.createElement('div')

  cellRecords.forEach(function (oneRecord) {
    const timeOfRecord = oneRecord.usableTimestamp.substring(11, 16)
    const productionLine = oneRecord.productionLine
    const workstationForm = oneRecord.workstationForm

    const recordElement = document.createElement('div')
    recordElement.classList.add('cellContentDiv')

    const linkElement = document.createElement('a')
    const urlToRecord = [
      baseUrlToiOS,
      oneRecord.productionLine,
      oneRecord.workstationForm,
      'edit',
      oneRecord._id
    ].join('/')
    linkElement.setAttribute('href', urlToRecord)
    linkElement.setAttribute('target', '_blank')

    const formNameFormatted = generateFormNameFormatted({
      productionLine,
      workstationForm
    })
    linkElement.innerText = [timeOfRecord, formNameFormatted].join(': ')

    recordElement.appendChild(linkElement)
    cellDiv.appendChild(recordElement)
  })

  return cellDiv
}
