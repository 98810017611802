import { round } from 'mathjs'
import * as d3 from 'd3'

/*
  Puts all data in an object
  [metricName]: {
    records: [] records
    dailyValues: [] values,
    monthlyValues: [] // TODO
    weeklyValues: [] // TODO
    mtdValues: {
        [YYYY-MM]: [] from 1 to 31
      }
    r7d1: []
    r7d7: []
    r28d1: []
    r28d28: []
    r31d1: []
    r31d31: []
  }
*/

/*
  Make Object for
  {
    dailyValues: [] of numbers
    records: [] of objects of records
    ... the different aggregation
  }
*/
export function generateDataSeries (params) {
  // // console.log('generateDataSeries', params)
  const timeframe = params.timeframe
  const metricName = params.metricName // Either metricName or metricName[] to sum multiple metric, eg. Frass from breeding and farming

  const metricsValues = {
    unit: params.unit,
    dailyValues: [],
    records: [],
    metricNames: metricName
  }

  // Create the basic arrays
  timeframe.forEach(function (oneDay) {
    const valueForDayObj = valueForDay(oneDay, params)
    metricsValues.dailyValues.push(valueForDayObj.value)
    metricsValues.records.push(valueForDayObj.records)
  })

  // Calculate Rolling Values
  metricsValues.r7d7 = seriesRollingAggregation({
    rollingDays: 7,
    rollingDivider: 1,
    dailyValues: metricsValues.dailyValues
  })

  metricsValues.r7d1 = seriesRollingAggregation({
    rollingDays: 7,
    rollingDivider: 7,
    dailyValues: metricsValues.dailyValues
  })

  metricsValues.r28d28 = seriesRollingAggregation({
    rollingDays: 28,
    rollingDivider: 1,
    dailyValues: metricsValues.dailyValues
  })

  metricsValues.r28d1 = seriesRollingAggregation({
    rollingDays: 28,
    rollingDivider: 28,
    dailyValues: metricsValues.dailyValues
  })

  metricsValues.r31d31 = seriesRollingAggregation({
    rollingDays: 31,
    rollingDivider: 1,
    dailyValues: metricsValues.dailyValues
  })

  metricsValues.r31d1 = seriesRollingAggregation({
    rollingDays: 31,
    rollingDivider: 31,
    dailyValues: metricsValues.dailyValues
  })

  // Add MTD
  metricsValues.mtdValues = seriesMTD({
    dailyValues: metricsValues.dailyValues,
    timeframe
  })

  return metricsValues
}

//
//
//
function valueForDay (oneDay, params) {
  const dailyValuesObj = params.dailyValuesObj
  const metricNames = params.metricName
  const unitMultiplier = params.unitMultiplier

  const valueForDayObj = {}

  if (typeof metricNames === 'string') {
    valueForDayObj.value = dailyValuesObj[oneDay]?.[metricNames]?.value
    valueForDayObj.records = dailyValuesObj[oneDay]?.[metricNames]?.records
  } else {
    let value
    let records
    metricNames.forEach(function (oneMetricName) {
      const oneMetricValue = dailyValuesObj[oneDay]?.[oneMetricName]?.value
      if (Number.isFinite(oneMetricValue)) {
        value = value || 0 // Define it now, so it remains undefined if no value is available for the day
        value += oneMetricValue

        records = records || []
        records.push(dailyValuesObj[oneDay]?.[oneMetricName]?.records)
      }
    })

    valueForDayObj.value = value
  }

  if (unitMultiplier) {
    valueForDayObj.value = valueForDayObj.value * unitMultiplier
  }
  return valueForDayObj
}

/*
  eg. 7-day rolling daily average
  rollingDays: 7
  rollingDivider: 7

  eg. 28-day rolling values
  rollingDays: 7
  rollingDivider: 1
*/
export function seriesRollingAggregation (params) {
  // // console.log('seriesRollingAggregation', params)
  const rollingDays = params.rollingDays
  const rollingDivider = params.rollingDivider || 1

  // The function can aggregate different values: daily, or rolling values
  const valuesToAggregate = params.valuesToAggregate || params.dailyValues

  const aggregateValues = []
  const rollingValues = [] // Keep values of preceding days
  valuesToAggregate.forEach(function (oneDailyValue, arrayIndex) {
    const value = Number.isFinite(oneDailyValue) ? oneDailyValue : 0 // null
    rollingValues.push(value)

    if (arrayIndex + 1 < rollingDays) {
      // Add 1 due to index being base 0
      aggregateValues.push(null)
      return
    }
    // Calculate the rolling value
    // // console.log('rollingValues', rollingValues)
    let rollingValue
    if (rollingDivider === rollingDays) {
      // D3 .mean() only uses defined values, so missing values don't lower the average; If we would like that, we would change the `value` above from being null to 0
      rollingValue = d3.mean(rollingValues)
    } else {
      rollingValue = d3.sum(rollingValues) / rollingDivider
    }

    if (Number.isFinite(rollingValue)) {
      rollingValue = round(rollingValue, 2)
    }

    aggregateValues.push(rollingValue)
    rollingValues.shift() // remove the first value of array
  })
  return aggregateValues
}

//
//
export function seriesMTD (params) {
  // // console.log('seriesMTD', params)
  const dailyValues = params.dailyValues
  const timeframe = params.timeframe

  const mtdValues = {}

  dailyValues.forEach(function (oneDailyValue, arrayIndex) {
    const value = Number.isFinite(oneDailyValue) ? oneDailyValue : 0
    const day = timeframe[arrayIndex]

    const month = day.substring(0, 7)

    mtdValues[month] = mtdValues[month] || []

    // Calculate the value to date
    const currentIndex = mtdValues[month].length
    const previousValue =
      currentIndex > 0 ? mtdValues[month][currentIndex - 1] : 0
    let mtdValue = previousValue + value
    mtdValue = round(mtdValue, 2)

    // We expect the dailyValues to be sorted, so we can push to the MTD array
    mtdValues[month].push(mtdValue)
  })
  return mtdValues
}
