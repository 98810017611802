// import * as d3 from 'd3'

import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'
import * as helpersDataParsing from '../../helpers/data/parsing.js'

/*
  filter
    s_trays: 12: todo?

  journey of a rack:
  input rack id
  show the history: setup, harvest...
*/

export const patternData = {}

export function showFarmingGC (params) {
  patternData.recordsSetupHarvest = params.records

  const finderParams = Object.assign(
    {
      propertyName: 's_growthChamber'
    },
    params
  )

  patternData.gcLines = helpersDataParsing.findPropertyValues(finderParams)
  const firstSetupDay = findFirstRecord(params)

  patternData.timeframe = helpersTimeseries.generateTimeframe({
    // minDay: '2022-03-16',
    minDay: firstSetupDay,
    sort: -1
  })

  // Index by day/GC
  indexRecords({
    records: patternData.recordsSetupHarvest
  })

  const tableContainer = generatePatternContent({
    timeframe: patternData.timeframe,
    gcLines: patternData.gcLines,
    records: patternData.recordsSetupHarvest,
    baseFilters: params.baseFilters
  })
  return tableContainer
}

export function generatePatternContent (params) {
  // // console.log('generatePatternContent', params)
  console.time('generatePatternContent')

  const timeframe = params.timeframe
  const gcLines = params.gcLines
  const records = params.records
  const baseFilters = params.baseFilters

  const patternValues = patternArray({
    timeframe,
    gcLines, // : ['GC_06'],
    records,
    baseFilters
  })

  const tableContainer = drawTable({
    timeframe,
    gcLines,
    patternValues
  })

  console.timeEnd('generatePatternContent')
  return tableContainer
}

function indexRecords (params) {
  const records = params.records
  console.time('indexRecords')

  // For setup
  // D3.js version: not familiar enough with the structure
  // patternData.groupedForSetups = Array.from(d3.groups(records, r => r.s_timestampDay)) //, r => r.s_growthChamber))
  // patternData.groupedForHarvests = Array.from(d3.groups(records, r => r.h_timestampDay))// , r => r.h_growthChamber))

  patternData.groupedForSetups = {}
  patternData.groupedForHarvests = {}
  records.forEach(function (oneRecord) {
    patternData.groupedForSetups[oneRecord.s_timestampDay] =
      patternData.groupedForSetups[oneRecord.s_timestampDay] || {}
    patternData.groupedForSetups[oneRecord.s_timestampDay][
      oneRecord.s_growthChamber
    ] =
      patternData.groupedForSetups[oneRecord.s_timestampDay][
        oneRecord.s_growthChamber
      ] || []
    patternData.groupedForSetups[oneRecord.s_timestampDay][
      oneRecord.s_growthChamber
    ].push(oneRecord)

    patternData.groupedForHarvests[oneRecord.h_timestampDay] =
      patternData.groupedForHarvests[oneRecord.h_timestampDay] || {}
    patternData.groupedForHarvests[oneRecord.h_timestampDay][
      oneRecord.h_growthChamber
    ] =
      patternData.groupedForHarvests[oneRecord.h_timestampDay][
        oneRecord.h_growthChamber
      ] || []
    patternData.groupedForHarvests[oneRecord.h_timestampDay][
      oneRecord.h_growthChamber
    ].push(oneRecord)
  })
  // console.log('patternData', patternData)
  console.timeEnd('indexRecords')
}

//
function drawTable (params) {
  // console.log('drawTable', params)
  console.time('drawTable')
  const gcLines = params.gcLines
  const timeframe = params.timeframe
  const patternValues = params.patternValues

  const tableContainer = document.createElement('div')
  tableContainer.classList.add('tableLayout')

  // Header
  const headerRowElement = document.createElement('div')
  headerRowElement.classList.add('row')

  const cellElement = document.createElement('div')
  cellElement.classList.add('cell')
  cellElement.classList.add('header')
  cellElement.classList.add('wide')
  cellElement.classList.add('bordered')
  cellElement.innerText = 'Date'
  headerRowElement.appendChild(cellElement)

  gcLines.forEach(function (oneLine) {
    const cellElement = document.createElement('div')
    cellElement.classList.add('cell')
    cellElement.classList.add('header')
    cellElement.classList.add('w60')
    cellElement.classList.add('bordered')
    cellElement.classList.add('center')
    cellElement.innerText = oneLine

    headerRowElement.appendChild(cellElement)
  })
  tableContainer.appendChild(headerRowElement)

  // Rows
  timeframe.forEach(function (oneDay, timeframeIndex) {
    const tableRowElement = document.createElement('div')
    tableRowElement.classList.add('row')

    const cellElement = document.createElement('div')
    cellElement.classList.add('cell')
    cellElement.classList.add('wide')
    cellElement.innerText = oneDay
    tableRowElement.appendChild(cellElement)

    gcLines.forEach(function (oneLine, lineIndex) {
      const cellElement = generateCell({
        patternValues,
        timeframeIndex,
        lineIndex
      })
      cellElement.setAttribute('day', oneDay)
      cellElement.setAttribute('line', oneLine)
      tableRowElement.appendChild(cellElement)
    })
    tableContainer.appendChild(tableRowElement)
  })

  console.timeEnd('drawTable')
  return tableContainer
}

function generateCell (params) {
  const patternValues = params.patternValues
  const timeframeIndex = params.timeframeIndex
  const lineIndex = params.lineIndex

  const cellElement = document.createElement('div')
  cellElement.classList.add('cell')
  cellElement.classList.add('w60')
  cellElement.classList.add('bordered')
  cellElement.classList.add('center')
  cellElement.classList.add('patternCell')

  const cellText = patternValues[timeframeIndex][1 + lineIndex]
  cellElement.innerText = cellText

  let isCellActive = false

  if (cellText.includes('S') && cellText.includes('H')) {
    cellElement.style.background =
      'linear-gradient(-15deg, #76a5af 50%, #9fc5e8 50%)'
    isCellActive = true
  } else if (cellText.includes('S')) {
    cellElement.style.backgroundColor = '#9fc5e8'
    isCellActive = true
  } else if (cellText.includes('H')) {
    cellElement.style.backgroundColor = '#76a5af'
    isCellActive = true
  }

  if (isCellActive) {
    cellElement.classList.add('clickable')
  }

  return cellElement
}

/*
  list of GCs
  date: number setup, number harvest
*/
function patternArray (params) {
  // // console.log('patternArray', params)
  console.time('patternArray')
  const timeframe = params.timeframe
  const patternLine = params.gcLines
  const records = params.records
  const baseFilters = params.baseFilters

  const pattern = []

  timeframe.forEach(function (oneDay) {
    const patternDay = []
    patternDay.push(oneDay)
    patternLine.forEach(function (oneLine) {
      const cellContent = generatePatternCell({
        records,
        oneDay,
        oneLine,
        baseFilters
      })
      patternDay.push(cellContent)
    })
    pattern.push(patternDay)
  })

  // // console.log('pattern', pattern)
  console.timeEnd('patternArray')
  return pattern
}

//
function generatePatternCell (params) {
  const records = findCellRecords({
    records: params.records,
    oneDay: params.oneDay,
    oneLine: params.oneLine,
    filters: params.filters,
    baseFilters: params.baseFilters
  })

  const setupRacks = records.setupRacks
  const harvestRacks = records.harvestRacks

  const cellValues = []
  if (setupRacks.length > 0) {
    cellValues.push('S:' + setupRacks.length)
  }

  if (harvestRacks.length > 0) {
    cellValues.push('H:' + harvestRacks.length)
  }

  return cellValues.join(' ')
}

//
export function findCellRecords (params) {
  // // console.log('findCellRecords', params)
  // Number Setup
  const setupRacks = findRecords({
    records: params.records,
    filtersDayGC: {
      type: 'setup',
      day: params.oneDay,
      line: params.oneLine
    },
    // filters: [
    //   {
    //     property: 's_timestampDay',
    //     value: params.oneDay
    //   },
    //   {
    //     property: 's_growthChamber',
    //     value: params.oneLine
    //   }
    // ],
    baseFilters: params.baseFilters
  })

  // Number Harvest
  const harvestRacks = findRecords({
    records: params.records,
    filtersDayGC: {
      type: 'harvest',
      day: params.oneDay,
      line: params.oneLine
    },
    // filters: [
    //   {
    //     property: 'h_timestampDay',
    //     value: params.oneDay
    //   },
    //   {
    //     property: 'h_growthChamber',
    //     value: params.oneLine
    //   }
    // ],
    baseFilters: params.baseFilters
  })

  return {
    setupRacks: setupRacks,
    harvestRacks: harvestRacks
  }
}

//
function findRecords (params) {
  // // console.log('findRecords', params)
  const baseFilters = params.baseFilters

  let groupedRecords
  if (params.filtersDayGC.type === 'setup') {
    groupedRecords = patternData.groupedForSetups
    // // console.log('setup')
  } else {
    groupedRecords = patternData.groupedForHarvests
    // // console.log('harvest')
  }

  const targetRecords =
    groupedRecords[params.filtersDayGC.day]?.[params.filtersDayGC.line]
  if (!targetRecords) return []

  // const records = params.records
  const records = targetRecords
  // // console.log('records', records)
  const matchingRecords = records.filter(function (oneRecord) {
    let isRecordPassing = true
    params.filters?.forEach(function (oneFilter) {
      if (oneRecord[oneFilter.property] === oneFilter.value) return
      isRecordPassing = false
    })

    if (baseFilters) {
      baseFilters.forEach(function (oneFilter) {
        if (oneFilter.filterOn === 'type') {
          if (oneRecord.s_type === oneFilter.value) return
          isRecordPassing = false
        }

        if (oneFilter.filterOn === 'harvestAge') {
          if (oneFilter.value === 'sameDay' && oneRecord.rackAgeDays <= 1) { return }
          isRecordPassing = false
        }
      })
    }
    return isRecordPassing
  })
  // // console.log('matchingRecords', matchingRecords)
  return matchingRecords
}

/*
  Identify the first record
  Add date YYYY-MM-DD properties
*/
function findFirstRecord (params) {
  const records = params.records

  let firstSetup = null

  records.forEach(function (oneRecord) {
    // Store YYYY-MM-DD dates
    oneRecord.s_timestampDay = oneRecord.s_timestamp?.substring(0, 10)
    oneRecord.h_timestampDay = oneRecord.h_timestamp?.substring(0, 10)

    if (!firstSetup || oneRecord.s_timestampDay < firstSetup) {
      firstSetup = oneRecord.s_timestampDay
    }
  })

  return firstSetup
}
