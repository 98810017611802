/*
    TODO
    - Allow AND and OR
    ... BUs should be one filter level
    ... Chart types should be one other level
*/

// Storing the filters applied
const layoutFilters = new Set()

export function manageLayout () {
  allowSearch()

  const chartTags = analyseLayoutTags()
  showFilterTags(chartTags)

  const filterContainer = document.getElementById('layoutFilters')

  filterContainer.addEventListener('click', function (clickEvent) {
    const clickElement = clickEvent.target
    if (!clickElement.closest('.layoutFilterTag')) return

    const filterTag = clickElement.getAttribute('filter')

    // console.log(clickElement, filterTag)

    if (layoutFilters.has(filterTag)) {
      layoutFilters.delete(filterTag)
    } else {
      layoutFilters.add(filterTag)
    }
    // console.log('layoutFilters', layoutFilters)
    toggleCharts()
    toggleTagChoices()
  })
}

let filterSearchTerm = '' // Store terms, allowing to analyse change in search
function allowSearch () {
  // console.log('allowSearch')
  const searchBox = 'searchChart'
  const searchElement = document.getElementById(searchBox)
  if (!searchElement) return

  const chartContainers = document.querySelectorAll('.chart')

  searchElement.addEventListener('keyup', function (changeEvent) {
    // // console.log(changeEvent)
    const searchString = searchElement.value
    if (
      filterSearchTerm === '' ||
      searchString.length < filterSearchTerm.length
    ) {
      resetAllContainersToShow()
    }

    filterSearchTerm = searchString

    // // console.log(searchString)

    chartContainers.forEach(function (oneChartContainer) {
      const chartId = oneChartContainer.getAttribute('id')
      if (!chartId) return

      if (chartId.toLowerCase().includes(searchString)) {
        oneChartContainer.classList.remove('hide')
      } else {
        oneChartContainer.classList.add('hide')
      }
    })

    hideContainersWithNoCharts()
  })
}

function hideContainersWithNoCharts () {
  const dashboardChartsContainers = document.querySelectorAll(
    '.dashboardChartsContainers'
  )

  // Analyse groups with no chart to hide them
  dashboardChartsContainers.forEach(function (oneDashboardChartsContainer) {
    // // console.log('check container', oneDashboardChartsContainer)
    const chartContainersTemp = oneDashboardChartsContainer.querySelectorAll(
      '.chart'
    )
    let numberVisibleCharts = 0
    chartContainersTemp.forEach(function (oneChartContainer) {
      // // console.log('chart...', oneChartContainer)
      const isVisible = !oneChartContainer.classList.contains('hide')
      if (isVisible) {
        numberVisibleCharts++
      }
    })
    // console.log(numberVisibleCharts)
    if (numberVisibleCharts === 0) {
      oneDashboardChartsContainer.classList.add('hide')
    }
  })
}

function resetAllContainersToShow () {
  const dashboardChartsContainers = document.querySelectorAll(
    '.dashboardChartsContainers'
  )

  // Analyse groups with no chart to hide them
  dashboardChartsContainers.forEach(function (oneDashboardChartsContainer) {
    oneDashboardChartsContainer.classList.remove('hide')
  })
}

//
function analyseLayoutTags () {
  // console.log('analyseLayoutTags')
  const chartContainers = document.querySelectorAll('.chart')

  const tags = new Set()

  chartContainers.forEach(function (oneChartContainer) {
    const classes = oneChartContainer.classList

    classes.forEach(function (oneClass) {
      if (oneClass === 'chart') return

      tags.add(oneClass)
    })
  })

  // console.log(tags)
  return tags
}

function showFilterTags (tags) {
  const filterContainer = document.getElementById('layoutFilters')

  const sortedTags = Array.from(tags).sort()

  sortedTags.forEach(function (oneTag) {
    const tagClicker = document.createElement('div')
    tagClicker.classList.add('layoutFilterTag')
    tagClicker.setAttribute('filter', oneTag)
    tagClicker.innerText = oneTag

    filterContainer.append(tagClicker)
  })
}

function toggleCharts () {
  // console.log('toggleCharts')
  const isFilterActive = layoutFilters.size > 0

  const chartContainers = document.querySelectorAll('.chart')
  chartContainers.forEach(function (oneChartContainer) {
    if (!isFilterActive) {
      oneChartContainer.classList.remove('hide')
      return
    }

    let shouldShow = false

    oneChartContainer.classList.forEach(function (oneClass) {
      if (layoutFilters.has(oneClass)) shouldShow = true
    })

    if (shouldShow) {
      oneChartContainer.classList.remove('hide')
    } else {
      oneChartContainer.classList.add('hide')
    }
  })
}

function toggleTagChoices () {
  // console.log('toggleTagChoices')
  const isFilterActive = layoutFilters.size > 0

  const tagContainers = document.querySelectorAll('.layoutFilterTag')
  tagContainers.forEach(function (oneTagContainer) {
    if (!isFilterActive) {
      oneTagContainer.classList.remove('disabled')
      return
    }

    let shouldShow = false

    oneTagContainer.classList.forEach(function (oneClass) {
      const filterTag = oneTagContainer.getAttribute('filter')

      if (layoutFilters.has(filterTag)) shouldShow = true
    })

    if (shouldShow) {
      oneTagContainer.classList.remove('disabled')
    } else {
      oneTagContainer.classList.add('disabled')
    }
  })
}
