import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'
import * as exportAttendance from './attendance.js'
import * as exportDrying from './drying.js'
import * as exportMaterialReceiving from './material-receiving.js'
import * as exportFeedPrep from './feed-prep.js'
import * as exportProductionData from './production-data.js'
import * as exportRackSetupHarvest from './rack-setup-harvest.js'

import htmlIndex from './index.html'

const availableExports = {
  attendance: exportAttendance,
  drying: exportDrying,
  materialReceiving: exportMaterialReceiving,
  feedPrep: exportFeedPrep,
  productionData: exportProductionData,
  rackSetupHarvest: exportRackSetupHarvest
}

export function show () {
  // console.log('module show')

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  listenClicks(contentElement)
}

function listenClicks (contentElement) {
  // console.log('listenClicks', contentElement)
  const exportClickableElements = contentElement.querySelectorAll(
    'div.downloadLink.forExport'
  )

  exportClickableElements.forEach(function (oneElement) {
    oneElement.addEventListener('click', function (clickEvent) {
      // console.log('click')
      const exportType = oneElement.getAttribute('exportType')
      // console.log(exportType)
      availableExports[exportType].loadAndexportCsv()
    })
  })
}
