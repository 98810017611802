import * as configVar from '../../config.js'

export async function loadData () {
  const recordValues = await loadApiData()
  return recordValues
}

//
async function loadApiData () {
  const urlApi = [configVar.apiRoutes, 'data', 'frass-activity'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  return fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      // console.log('api loaded', urlApi)
      // console.log(data)
      const recordValues = data.data

      return recordValues
    })
}
