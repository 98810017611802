export function getFirstIndex (params) {
  const dateTimeframe = params.timeframe

  let firstIndex = 0
  if (params.dates?.min) {
    firstIndex = dateTimeframe.indexOf(params.dates.min)
    if (firstIndex < 0) firstIndex = 0 // Avoid issue: start from 0 at least, not -1 if not present
  }
  return firstIndex
}

export function getLastIndex (params) {
  const dateTimeframe = params.timeframe

  let lastIndex = dateTimeframe.length
  if (params.dates?.max) {
    const foundLastIndex = dateTimeframe.indexOf(params.dates.max)
    if (foundLastIndex > 0) lastIndex = foundLastIndex + 1 // Add 1 index, because the .slice(start, end) function does not include the end paramenet
  }
  return lastIndex
}

export function getDayWithOffset (offsetDays) {
  const dayOffset = offsetDays || 0
  const currentDate = new Date()
  return new Date(currentDate.setDate(currentDate.getDate() + dayOffset))
    .toJSON()
    .substring(0, 10)
}

export function generateTimeframe (params = {}) {
  // // console.log('generateTimeframe', params)
  const minDate = new Date(params.minDay)
  const maxDate = params.maxDay ? new Date(params.maxDay) : new Date()
  const periodicity = params.periodicity || 'd'

  const timeFrameDays = []

  const currentDate = minDate
  while (currentDate.valueOf() <= maxDate.valueOf()) {
    let currentDay
    if (periodicity === 'm') {
      currentDay = currentDate.toJSON().substring(0, 7)
    } else {
      currentDay = currentDate.toJSON().substring(0, 10)
    }
    timeFrameDays.push(currentDay)

    if (periodicity === 'm') {
      currentDate.setMonth(currentDate.getMonth() + 1)
    } else {
      currentDate.setDate(currentDate.getDate() + 1)
    }
  }

  if (Number.isFinite(params.sort)) {
    // console.log('sort timeframe', params.sort, timeFrameDays)

    timeFrameDays.sort(function (t1, t2) {
      const date1 = new Date(t1)
      const date2 = new Date(t2)
      return params.sort * (date1 - date2)
    })
  }

  return timeFrameDays
}
