/*
  Identify the different GCs
*/
export function findPropertyValues (params) {
  const propertyName = params.propertyName

  const records = params.records
  const propertyValues = new Set()

  records.forEach(function (oneRecord) {
    const propertyValue = oneRecord[propertyName]

    propertyValues.add(propertyValue)
  })

  const propertyValuesArray = Array.from(propertyValues)
  propertyValuesArray.sort()
  // // console.log(propertyValuesArray)

  return propertyValuesArray
}
