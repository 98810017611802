import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import accounting from 'accounting'

import { chartInstances } from './instances.js'
Chart.register(...registerables)

export function drawPeriodCharts (params = {}) {
  const chartParams = params.chartParams
  const metricNames = chartParams.metricNames

  metricNames.forEach(function (oneMetricName) {
    drawPeriodChart({
      metricName: oneMetricName,
      dailyTargets: chartParams.dailyTargets,
      timeframe: chartParams.timeframe,
      metricsDB: chartParams.metricsDB,
      dates: chartParams.dates
    })
  })
}

export function drawPeriodChart (params = {}) {
  // // console.log('drawPeriodChart', params)
  const metricName = params.metricName
  const metricsDB = params.metricsDB
  const metricDB = metricsDB[metricName]
  const chartElementId = params.chartElementId
  const valueMultiplier = params.valueMultiplier || 1
  // const dailyTargets = params.dailyTargets

  const config = {
    type: 'bar',
    plugins: [ChartDataLabels],
    options: {
      maintainAspectRatio: false, // Fill the container size
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          },
          stacked: true
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: metricDB.unit
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Monthly: ' + metricName
        },
        legend: {
          display: false
        },
        datalabels: {
          formatter: function (value) {
            return accounting.formatNumber(value, 0, ',', '.')
          },
          font: {
            weight: 'bold'
          }
        }
      }
    }
  }

  const chartContainerId = chartElementId || 'm_' + metricName
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  // Find the months to include
  let minMonth
  if (params.dates.min) {
    minMonth = params.dates.min.substring(0, 7)
  }

  let maxMonth
  if (params.dates.max) {
    maxMonth = params.dates.max.substring(0, 7)
  }

  const includedMonths = []
  Object.keys(metricDB.mtdValues).forEach(function (oneMTDmonth) {
    // Exclude months before min date; and after max date if that's defined
    if (minMonth && minMonth > oneMTDmonth) return
    if (maxMonth && oneMTDmonth > maxMonth) return

    includedMonths.push(oneMTDmonth)
  })

  // Set the Chart to have such xAxis labels
  const chartData = {
    labels: includedMonths,
    datasets: []
  }

  const showDetailsOfPeriod = false

  if (Array.isArray(metricDB.metricNames) && showDetailsOfPeriod) {
    const colors = ['#FFAEBC', '#A0E7E5']
    metricDB.metricNames.forEach(function (oneMetricName, arrayIndex) {
      const periodValues = []
      const thisMetricDB = metricsDB[oneMetricName]
      includedMonths.forEach(function (oneMTDmonth, arrayIndex) {
        const mtdValues = thisMetricDB.mtdValues[oneMTDmonth]
        const periodValue = valueMultiplier * mtdValues[mtdValues.length - 1]
        periodValues.push(periodValue)
      })
      chartData.datasets.push({
        label: oneMetricName,
        data: periodValues,
        type: 'bar',
        backgroundColor: colors[arrayIndex], // '#bed2f4',
        order: 1,
        borderRadius: 4,
        datalabels: {
          align: 'center',
          anchor: 'center'
        }
      })
    })
    config.options.plugins.legend.display = true
    config.options.plugins.legend.position = 'bottom'
  } else {
    const periodValues = []
    includedMonths.forEach(function (oneMTDmonth, arrayIndex) {
      const mtdValues = metricDB.mtdValues[oneMTDmonth]
      const periodValue = valueMultiplier * mtdValues[mtdValues.length - 1]
      periodValues.push(periodValue)
    })

    chartData.datasets.push({
      label: metricName,
      data: periodValues,
      type: 'bar',
      backgroundColor: '#bed2f4',
      order: 1,
      borderRadius: 4,
      datalabels: {
        align: 'end',
        anchor: 'end'
      }
    })
  }

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}
