import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

export function loadAndexportCsv () {
  // console.log('loadAndexportCsv')
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'csv_exports', 'production-data'].join(
    '/'
  )

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  console.time('loadAndexportCsv')
  fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (receivedJson) {
      console.timeEnd('loadAndexportCsv')
      // console.log('api loaded', urlApi)
      // console.log(receivedJson)
      const recordValues = receivedJson.data

      const exportArray = productionData.generateExportArray(recordValues)
      const csvFormatted = d3.csvFormat(exportArray)
      helpersCsvExports.downloadCsv(csvFormatted)
    })
}

const productionData = {}

productionData.generateExportArray = function (records) {
  const exportArray = []

  const numberRows = 50000000

  records.forEach(function (oneRecord) {
    const rowData = productionData.exportRow(oneRecord)

    if (exportArray.length > numberRows) return
    exportArray.push(rowData)
  })
  return exportArray
}

// Decide the properties to enforce order in csv
productionData.exportRow = function (oneRecord) {
  const oneRow = {}

  const recordValues = oneRecord

  // Timestamps
  oneRow.timestamp = recordValues.timestamp
  oneRow.submission_timestamp = recordValues.submission_timestamp
  // oneRow.timestampsDelta = recordValues.timestampsDelta

  // Basics
  oneRow.productionLine = recordValues.productionLine
  oneRow.workstationForm = recordValues.workstationForm
  oneRow.user_name = recordValues.user_name
  oneRow.usableTimestamp = recordValues.usableTimestamp
  // oneRow.type = recordValues.type

  oneRow.farmingFreshLarvaeProduction =
    recordValues.farmingFreshLarvaeProduction
  oneRow.farmingLarvaeHarvestAverageWeight =
    recordValues.farmingLarvaeHarvestAverageWeight
  oneRow.farmingFrassProduction = recordValues.farmingFrassProduction
  oneRow.eggsCollection = recordValues.eggsCollection
  oneRow.babyLarvaeProduction = recordValues.seedlingsProduction
  oneRow.seedlingsAverageWeightProduction = recordValues.seedlingsAverageWeight
  oneRow.breedingFeedstockUse = recordValues.breedingFeedstockUse
  oneRow.pupaeProduction = recordValues.pupaeProduction
  oneRow.farmingFeedstockUse = recordValues.farmingFeedstockUse
  oneRow.prepupaeAverageWeightProduction = recordValues.prepupaeAverageWeight
  oneRow.prepupaeProduction = recordValues.prepupaeProduction
  oneRow.breedingFrassProduction = recordValues.breedingFrassProduction
  oneRow.manufacturingDriedLarvaeProduction =
    recordValues.manufacturingDriedLarvaeProduction

  return oneRow
}
