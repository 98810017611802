import * as userModule from './helpers/auth/user.js'

// Track that User has been loaded (So navigating from one page to another does not force a reload)
let isUserLoaded = false

export async function pageLoader (params = {}) {
  // console.log('*** pageLoader', 'isUserLoaded', isUserLoaded, params)

  const loaders = []

  // Load User by default
  const isForceLoadUser = params.loadUser === true
  const isDefaultLoadUserWhenNotAlready =
    typeof params.loadUser === 'undefined' && !isUserLoaded
  if (isForceLoadUser || isDefaultLoadUserWhenNotAlready) {
    // console.log('load user')
    const loaderUser = userModule.loadUser().then(function () {
      // console.log('*** pageLoader inside then')
      isUserLoaded = true
      return 1
    })

    loaders.push(loaderUser)
  }

  return Promise.all(loaders)
}

function launchGoogleAnalytics () {
  // console.log('launchGoogleAnalytics?', window.location)
  if (window.location.hostname === 'localhost') return
  if (window.location.hostname.includes('staging')) return

  // console.log('launch Analytics')
  gtag('config', 'G-86M711E4PP')
}

launchGoogleAnalytics()
