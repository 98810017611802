import * as inventoryHelpers from './helpers.js'
import * as chartsBarBreakdowns from '../../helpers/charts/bar-breakdowns.js'

export function showRunningInventoryChart () {
  const chartParams = inventoryHelpers.chartParams
  // console.log('showRunningInventoryChart', chartParams)

  const flowsPerIngredient = chartParams.flowsPerIngredient
  const timeframe = chartParams.timeframe

  const _chartParams = {
    chartContainerId: 'runningInventory',
    unit: 'Kg',
    timeframe,
    dates: chartParams.dates,
    datasets: []
  }

  const excludedMaterials = ['water', 'frass', 'frass matured', 'exuvia']

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    const data = flowsPerIngredient[oneIngredient].runningInventory

    if (excludedMaterials.includes(oneIngredient)) return

    _chartParams.datasets.push({
      name: oneIngredient,
      values: data.values
    })
  })

  chartsBarBreakdowns.drawBarBreakdownChart(_chartParams)
}

export function showUsageChart () {
  const chartParams = inventoryHelpers.chartParams
  // console.log('showUsageChart', chartParams)

  const flowsPerIngredient = chartParams.flowsPerIngredient
  const timeframe = chartParams.timeframe

  const _chartParams = {
    chartContainerId: 'totalUsage',
    unit: 'Kg',
    timeframe,
    dates: chartParams.dates,
    datasets: []
  }

  const excludedMaterials = ['water']

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    const data = flowsPerIngredient[oneIngredient].totalUsage

    if (excludedMaterials.includes(oneIngredient)) return

    _chartParams.datasets.push({
      name: oneIngredient,
      values: data.values
    })
  })

  chartsBarBreakdowns.drawBarBreakdownChart(_chartParams)
}
