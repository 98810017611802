import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

export function loadAndexportCsv () {
  // console.log('loadAndexportCsv')
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'csv_exports', 'drying'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      // console.log('api loaded', urlApi)
      // console.log(data)
      // const recordValues = helpersCsvExports.couchRecordsToArrayValues(data)
      const recordValues = data.data
      const withColumns = csvColumns(recordValues)
      const csvFormatted = d3.csvFormat(withColumns)
      helpersCsvExports.downloadCsv(csvFormatted)
    })
}

function csvColumns (recordValues) {
  const recordsArray = []

  recordValues.forEach(function (oneRecord) {
    const oneRow = {}
    oneRow.type = oneRecord.type
    oneRow.productionLine = oneRecord.productionLine
    oneRow.workstationForm = oneRecord.workstationForm
    oneRow.user_name = oneRecord.user_name

    // Timestamps
    oneRow.usableTimestamp = oneRecord.usableTimestamp
    oneRow.timestamp = oneRecord.timestamp
    oneRow.submission_timestamp = oneRecord.submission_timestamp
    oneRow.timestampsDelta = oneRecord.timestampsDelta

    // Form values
    oneRow.energyAtTimeIn = oneRecord.energyAtTimeIn
    oneRow.energyAtTimeOut = oneRecord.energyAtTimeOut
    oneRow.energyUsed = oneRecord.energyUsed

    oneRow.input = oneRecord.input
    oneRow.output = oneRecord.output

    oneRow.energyPerKgOutput = oneRecord.energyPerKgOutput

    oneRow.timeIn = oneRecord.timeIn
    oneRow.timeOut = oneRecord.timeOut
    oneRow.timeDrying = oneRecord.timeDrying

    oneRow.form = oneRecord.from

    recordsArray.push(oneRow)
  })
  return recordsArray
}
