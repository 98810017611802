import * as inventoryHelpers from './helpers.js'

import * as inventoryOverallCharts from './overall-charts.js'
import * as inventoryMaterialsCharts from './material-charts.js'
import * as inventoryMonthlyCharts from './monthly-charts.js'

//
//
// Update the timeframe of the charts
export function listenToStartDateSelector () {
  const minDateSelectorElement = document.getElementById('minDateSelector')
  minDateSelectorElement.addEventListener('change', function () {
    const minDay = minDateSelectorElement.value

    updateChartsStartDate({
      minDay: minDay
    })
  })
}

export function listenToEndDateSelector () {
  const maxDateSelectorElement = document.getElementById('maxDateSelector')
  maxDateSelectorElement.addEventListener('change', function () {
    const maxDay = maxDateSelectorElement.value

    updateChartsEndDate({
      maxDay: maxDay
    })
  })
}

export function listenToTimeframeShortcut () {
  // console.log('listenToTimeframeShortcut')

  const timeframeSelectorElement = document.getElementById('timeframeSelector')
  timeframeSelectorElement.addEventListener('click', function (clickEvent) {
    const linkElement = clickEvent.target.closest('a')
    if (!linkElement) return
    clickEvent.preventDefault()

    const timeframeDays = +linkElement.getAttribute('numberDays')
    updateTimeframe(timeframeDays)
  })

  function updateTimeframe (timeframeDays) {
    const targetDate = new Date()
    targetDate.setDate(targetDate.getDate() - timeframeDays)
    const firstDay = targetDate.toJSON().substring(0, 10)

    updateChartsStartDate({
      minDay: firstDay
    })
  }
}

//
//
function updateChartsStartDate (params) {
  // console.log('updateChartsStartDate', params)
  const minDate = params.minDay
  inventoryHelpers.setChartParams({
    minDate: minDate
  })
  refreshCharts()
}

function updateChartsEndDate (params) {
  // console.log('updateChartsEndDate', params)
  const maxDate = params.maxDay
  inventoryHelpers.setChartParams({
    maxDate: maxDate
  })
  refreshCharts()
}

function refreshCharts () {
  inventoryMaterialsCharts.showIngredientCharts()
  inventoryOverallCharts.showRunningInventoryChart()
  inventoryOverallCharts.showUsageChart()
  inventoryMonthlyCharts.showMonthlyUsageChart()
}
