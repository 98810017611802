import * as recordsSettings from './settings.js'
import * as recordsShow from './show.js'

// Storing the filters applied

const filterElementId = 'layoutFilters'

export function manageLayout (params) {
  const userTags = analyseLayoutTags(params)
  showFilterTags(userTags)

  const filterContainer = document.getElementById(filterElementId)

  filterContainer.addEventListener('click', function (clickEvent) {
    const clickElement = clickEvent.target
    if (!clickElement.closest('.layoutFilterTag')) return

    const filterTag = clickElement.getAttribute('filter')
    // console.log(clickElement, filterTag)

    if (recordsSettings.layoutFilters.has(filterTag)) {
      recordsSettings.layoutFilters.delete(filterTag)
    } else {
      recordsSettings.layoutFilters.add(filterTag)
    }
    // console.log('layoutFilters', recordsSettings.layoutFilters)
    toggleTagChoices()

    recordsShow.showFormRecords()
  })

  toggleTagChoices()
  // console.log('manageLayout', recordsSettings.layoutFilters)
}

//
//
function analyseLayoutTags (params) {
  // console.log('analyseLayoutTags')

  const usersList = recordsSettings.usersList

  const tags = new Set()

  Object.keys(usersList).forEach(function (oneUser) {
    const userTags = usersList[oneUser]

    userTags.forEach(function (oneTag) {
      tags.add(oneTag)
    })
  })

  // console.log(tags)
  return tags
}

//
//
function showFilterTags (tags) {
  // console.log('showFilterTags', tags)
  const filterContainer = document.getElementById(filterElementId)

  const sortedTags = Array.from(tags).sort(function (v1, v2) {
    const v1Order =
      recordsSettings.defaultSort.indexOf(v1) >= 0
        ? recordsSettings.defaultSort.indexOf(v1)
        : 99
    const v2Order =
      recordsSettings.defaultSort.indexOf(v2) >= 0
        ? recordsSettings.defaultSort.indexOf(v2)
        : 99
    return v1Order - v2Order
  })
  sortedTags.push('unclassifiedUsers')

  sortedTags.forEach(function (oneTag) {
    const tagClicker = document.createElement('div')
    tagClicker.classList.add('layoutFilterTag')
    tagClicker.setAttribute('filter', oneTag)
    tagClicker.innerText = oneTag

    filterContainer.append(tagClicker)
  })
}

function toggleTagChoices () {
  // console.log('toggleTagChoices')
  const isFilterActive = recordsSettings.layoutFilters.size > 0

  const tagContainers = document.querySelectorAll('.layoutFilterTag')
  tagContainers.forEach(function (oneTagContainer) {
    if (!isFilterActive) {
      oneTagContainer.classList.remove('disabled')
      return
    }

    let shouldShow = false

    oneTagContainer.classList.forEach(function (oneClass) {
      const filterTag = oneTagContainer.getAttribute('filter')

      if (recordsSettings.layoutFilters.has(filterTag)) shouldShow = true
    })

    if (shouldShow) {
      oneTagContainer.classList.remove('disabled')
    } else {
      oneTagContainer.classList.add('disabled')
    }
  })
}
