import { chartInstances } from '../../helpers/charts/instances.js'

import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'

import * as chartsTrends from '../../helpers/charts/trends.js'
import * as chartsMTD from '../../helpers/charts/mtd.js'
import * as chartsRatios from '../../helpers/charts/ratios.js'
import * as chartsPeriods from '../../helpers/charts/periods.js'

const chartParams = {}
export function setChartParams (params = {}) {
  // console.log('setChartParams', params)

  // Metrics name for the different types of charts
  if (params.metricNames) {
    chartParams.metricNames = params.metricNames
  }
  if (params.mtdNames) {
    chartParams.mtdNames = params.mtdNames
  }
  if (params.ratioNames) {
    chartParams.ratioNames = params.ratioNames
  }
  if (params.periodNames) {
    chartParams.periodNames = params.periodNames
  }
  //

  if (params.highlights) {
    chartParams.highlights = params.highlights
  }

  if (params.dailyTargets) {
    chartParams.dailyTargets = params.dailyTargets
  }

  if (params.timeframe) {
    chartParams.timeframe = params.timeframe
  }

  if (params.metricsDB) {
    chartParams.metricsDB = params.metricsDB
    // Ie set as default only
    chartParams.valueSeries = [
      'dailyValues',
      {
        series: 'r7d1',
        type: 'line'
      }
    ]
  }

  chartParams.dates = chartParams.dates || {}
  if (params.minDate) {
    chartParams.dates.min = params.minDate
  } else if (!chartParams?.dates?.min) {
    const daysInPast = 14
    chartParams.dates.min = helpersTimeseries.getDayWithOffset(-daysInPast)
  }

  if (params.maxDate) {
    chartParams.dates.max = params.maxDate
  } else if (!chartParams?.dates?.max) {
    // Default to today
    chartParams.dates.max = new Date().toJSON().substring(0, 10)
  }

  return chartParams
}

//
//
export function allowFullScreen () {
  const chartContainerElements = document.querySelectorAll('div.chart')

  chartContainerElements.forEach(function (chartContainerElement) {
    const openerToggle = document.createElement('div')
    openerToggle.classList.add('fullScreenToggle')
    chartContainerElement.append(openerToggle)
  })

  // Listener
  document.addEventListener('click', function (clickEvent) {
    const targetElement = clickEvent.target
    if (!targetElement.classList.contains('fullScreenToggle')) return

    const chartContainer = targetElement.closest('div.chart')
    chartContainer.classList.toggle('fullScreen')
  })
}

//
//
export function allowDownload () {
  const chartContainerElements = document.querySelectorAll('div.chart')

  chartContainerElements.forEach(function (chartContainerElement) {
    const openerToggle = document.createElement('div')
    openerToggle.classList.add('downloadButton')
    chartContainerElement.append(openerToggle)
  })

  // Listener
  document.addEventListener('click', function (clickEvent) {
    const targetElement = clickEvent.target
    if (!targetElement.classList.contains('downloadButton')) return

    const chartContainer = targetElement.closest('div.chart')
    const chartId = chartContainer.getAttribute('id')

    const chartInstance = chartInstances[chartId]
    if (!chartInstance) return

    const a = document.createElement('a')

    // Create the image and download id
    a.href = chartInstance.toBase64Image()
    a.download = [chartId, '.png'].join('')
    a.click()
  })
}

// Which data to show on the trend charts
export function listenToUpdateTrendCharts () {
  // console.log('updateTrendCharts')

  // We toggle the valueSerie
  const checkboxElementsContainer = document.getElementById(
    'trendChartsValueSeries'
  )
  const checkboxElements = checkboxElementsContainer.querySelectorAll('input')

  checkboxElements.forEach(function (oneCheckboxElement) {
    oneCheckboxElement.addEventListener('click', function (clickEvent) {
      // console.log('click', clickEvent)
      const changedCheckbox = clickEvent.target.closest(
        'input[type="checkbox"]'
      )
      console.log('changedCheckbox', changedCheckbox)

      readAllChoices()
      chartsTrends.drawTrendCharts({
        chartParams
      })
    })
  })

  function readAllChoices () {
    const valueSeries = []
    checkboxElements.forEach(function (oneCheckboxElement) {
      if (!oneCheckboxElement.checked) return

      const valueSerie = oneCheckboxElement.getAttribute('id').substring(2)

      if (valueSerie === 'dailyValues') {
        valueSeries.push('dailyValues')
      } else {
        valueSeries.push({
          series: valueSerie,
          type: 'line'
        })
      }
    })
    chartParams.valueSeries = valueSeries
  }
}

//
//
// Update the timeframe of the charts
export function listenToStartDateSelector () {
  const minDateSelectorElement = document.getElementById('minDateSelector')
  minDateSelectorElement.addEventListener('change', function () {
    const minDay = minDateSelectorElement.value

    updateChartsStartDate({
      minDay: minDay
    })
  })
}

export function listenToEndDateSelector () {
  const maxDateSelectorElement = document.getElementById('maxDateSelector')
  maxDateSelectorElement.addEventListener('change', function () {
    const maxDay = maxDateSelectorElement.value

    updateChartsEndDate({
      maxDay: maxDay
    })
  })
}

export function listenToTimeframeShortcut () {
  // console.log('listenToTimeframeShortcut')

  const timeframeSelectorElement = document.getElementById('timeframeSelector')
  timeframeSelectorElement.addEventListener('click', function (clickEvent) {
    const linkElement = clickEvent.target.closest('a')
    if (!linkElement) return
    clickEvent.preventDefault()

    const timeframeDays = +linkElement.getAttribute('numberDays')
    updateTimeframe(timeframeDays)
  })

  function updateTimeframe (timeframeDays) {
    const targetDate = new Date()
    targetDate.setDate(targetDate.getDate() - timeframeDays)
    const firstDay = targetDate.toJSON().substring(0, 10)

    updateChartsStartDate({
      minDay: firstDay
    })
  }
}

//
//
function updateChartsStartDate (params) {
  const minDate = params.minDay
  setChartParams({
    minDate: minDate
  })
  refreshCharts()
}

function updateChartsEndDate (params) {
  // console.log('updateChartsEndDate', params)
  const maxDate = params.maxDay
  setChartParams({
    maxDate: maxDate
  })
  refreshCharts()
}

function refreshCharts () {
  // console.log('refreshCharts', chartParams)
  chartsTrends.drawTrendCharts({
    chartParams
  })

  chartsMTD.drawMTDCharts({
    chartParams
  })

  chartsRatios.drawRatioCharts({
    chartParams
  })

  chartsPeriods.drawPeriodCharts({
    chartParams
  })
}
