import { Chart, registerables } from 'chart.js'
import { round } from 'mathjs'

import { chartInstances } from './instances.js'
Chart.register(...registerables)

export function drawMTDCharts (params = {}) {
  const chartParams = params.chartParams
  const metricNames = chartParams.metricNames

  metricNames.forEach(function (oneMetricName) {
    drawMTDChart({
      metricName: oneMetricName,
      dailyTargets: chartParams.dailyTargets,
      metricsDB: chartParams.metricsDB,
      dates: chartParams.dates
    })
  })
}

function drawMTDChart (params = {}) {
  // // console.log('drawMTDChart', params)
  const metricName = params.metricName
  const metricsDB = params.metricsDB
  const metricDB = metricsDB[metricName]
  const dailyTargets = params.dailyTargets

  const isShowTarget = true // Put to false to now show targets

  const config = {
    type: 'line',
    options: {
      // responsive: true,
      maintainAspectRatio: false, // Fill the container size
      plugins: {
        title: {
          display: true,
          text: 'Month To Date (MTD): ' + metricName
        },
        legend: {
          position: 'right',
          reverse: true
        },
        autocolors: {
          enabled: false
        }
      },
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          },
          title: {
            display: true,
            text: 'Day of Month'
          }
        },
        y: {
          title: {
            display: true,
            text: metricDB.unit
          }
        }
      },
      interaction: {
        // mode: 'index',
        intersect: false
      }
    }
  }

  const chartContainerId = 'mtd_' + metricName
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  // Generate Day Series
  const daySeries = []
  for (let x = 1; x <= 31; x++) {
    daySeries.push(x)
  }

  // Find the months to include
  let minMonth
  if (params.dates.min) {
    minMonth = params.dates.min.substring(0, 7)
  }
  let maxMonth
  let maxDay
  if (params.dates.max) {
    maxMonth = params.dates.max.substring(0, 7)
    maxDay = params.dates.max.substring(8, 10)
  }

  const includedMonths = []
  Object.keys(metricDB.mtdValues).forEach(function (oneMTDmonth) {
    // Exclude months before min date; and after max date if that's defined
    if (minMonth && minMonth > oneMTDmonth) return
    if (maxMonth && oneMTDmonth > maxMonth) return

    includedMonths.push(oneMTDmonth)
  })

  const chartData = {
    labels: daySeries,
    datasets: []
  }

  includedMonths.forEach(function (oneMTDmonth, arrayIndex) {
    const monthFromLast = includedMonths.length - arrayIndex
    let color
    let width = 2
    let radius = 0 // No point visible (but still there for mouseover)

    if (monthFromLast === 1) {
      color = '#4285f4'
      width = 3
      radius = 2
    } else if (monthFromLast === 2) {
      color = 'rgba(66,133,244,0.5)'
      width = 3
      radius = 2
    } else {
      width = 6 * Math.max(0.1, 1 / monthFromLast)
      color = 'rgba(0,0,0,XX)'.replace('XX', Math.max(0.1, 1 / monthFromLast))
    }

    let mtdValues
    if (maxDay && monthFromLast === 1) {
      // For the last month, only show until max date
      mtdValues = metricDB.mtdValues[oneMTDmonth].slice(0, maxDay)
    } else {
      mtdValues = metricDB.mtdValues[oneMTDmonth]
    }

    chartData.datasets.push({
      label: oneMTDmonth,
      data: mtdValues,
      type: 'line',
      // --
      // Line
      borderWidth: width,
      borderColor: color,
      // --
      // Points
      radius: radius
      // pointRadius: 1
    })
  })

  // Show target
  const monthlyTarget = dailyTargets[metricName]
    ? dailyTargets[metricName] * 30
    : false
  if (monthlyTarget && isShowTarget) {
    const targetSeries = []
    const dailyTargetIncrement = monthlyTarget / 31
    for (let x = 1; x <= 31; x++) {
      const targetValue = round(x * dailyTargetIncrement)
      targetSeries.push(targetValue)
    }
    chartData.datasets.push({
      label: 'Target',
      data: targetSeries,
      type: 'line',
      fill: 'origin', // Fill to xAxis
      backgroundColor: 'rgba(247,177,24,0.1)',
      // --
      // Line
      borderWidth: 2,
      borderColor: 'rgba(247,177,24,0.1)',
      // --
      // Points
      radius: 0
      // pointRadius: 1
    })
  }

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}
