import * as configVar from '../../config.js'

// Use storage in the module
const moduleStore = {}
moduleStore.userData = false
// console.log('userModule, set userData')

// Define the DOM element IDs
const userHeaderElementId = 'signin'

export function userData () {
  return moduleStore.userData
}

export function loginUser (apiResponse) {
  // console.log('loginUser', apiResponse)
  // Record when we received an API response
  if (apiResponse?.email && apiResponse?.isAuthorised) {
    // console.log('set user data')
    moduleStore.userData = apiResponse
  }
  showUserHeader()
}

export function logoutUser () {
  moduleStore.userData = false
  showUserHeader()
}

export function isUserLoggedIn () {
  // console.log('isUserLoggedIn?', moduleStore.userData)
  if (moduleStore.userData) {
    return true
  } else {
    return false
  }
}

export function showUserHeader () {
  // console.log('showUserHeader')

  if (isUserLoggedIn()) {
    showLoggedInUser()
  } else {
    showLoggedOutUser()
  }
}

export function loadUser () {
  // console.log('loadUser')
  const urlApi = [configVar.authRoutes, 'isLoggedIn'].join('/')
  const fetchOptions = {
    method: 'GET'
  }

  return fetch(urlApi, fetchOptions)
    .then(function (response) {
      // console.log('user response', response)
      // console.log('response', response.status)
      if (response.status === 401) return // Unauthorised
      return response.json()
    })
    .catch(function (error) {
      console.error('loadUser', error)
    })
    .then(function (response) {
      // console.log('response', response)
      loginUser(response)
      // refreshAppModules()
      return 'loadUser done'
    })
}

function showLoggedOutUser () {
  const DOMelement = document.getElementById(userHeaderElementId)
  if (!DOMelement) return

  DOMelement.innerHTML = loginButton()
}

function showLoggedInUser () {
  // console.log('showLoggedInUser')
  const DOMelement = document.getElementById(userHeaderElementId)
  if (!DOMelement) return

  // // console.log('userData', userData())

  let userHeader = []
  userHeader = generateUserHeader()

  const html = userHeader.join('')
  DOMelement.innerHTML = html
}

function loginButton () {
  const button = []
  button.push('<a href="/auth/login/google" class="btn btn-primary">')
  button.push('<i class="fab fa-google"></i>')
  button.push(' Sign in with Google')
  button.push('</a>')

  return button.join('')
}

function generateUserHeader () {
  const signedInUser = []
  signedInUser.push('<ul>')
  signedInUser.push('<li>', userData().email, '</li>')
  signedInUser.push('</ul>')
  signedInUser.push('<a href="/auth/logout">Logout</a>')

  return signedInUser
}
