import * as d3 from 'd3'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

import htmlIndex from './index.html'

export function show (urlParams = {}) {
  // console.log('module show', urlParams)

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  if (urlParams.subpage === 'analysis') {
    showFormsAnalysis(urlParams)
    return
  }

  if (urlParams.subpage === 'explore') {
    showFormDetails(urlParams)
  }
}

//
//
function showFormsAnalysis (urlParams) {
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'data', 'forms'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (receivedJson) {
      // console.log('api loaded', urlApi)
      // console.log(receivedJson)
      const formKinds = receivedJson.data

      showFormsList(formKinds)
    })
}

//
//
function showFormDetails (urlParams) {
  // console.log('showFormDetails, urlParams', urlParams)

  // Calling backend
  const formParams = [
    urlParams.type,
    urlParams.productionLine,
    urlParams.workstationForm
  ].join('/')
  const urlApi = [
    configVar.apiRoutes,
    'data',
    'forms-details',
    formParams
  ].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (receivedJson) {
      // console.log('api loaded', urlApi)
      // console.log(receivedJson)
      const formDetails = receivedJson.data

      helpersCsvExports.analyseProperties(formDetails)

      const csvFormatted = d3.csvFormat(formDetails)
      helpersCsvExports.downloadCsv(csvFormatted)
    })
}

//
//
function showFormsList (formKinds) {
  // console.log('showFormsList', formKinds)
  const containerID = 'formsList'

  // Create a table
  const tableNode = document.createElement('table')

  // Header
  const headerValues = [
    'type',
    'productionLine',
    'workstationForm',
    'first',
    'last',
    'users',
    'records'
  ]
  // Create row Node
  const rowNode = document.createElement('tr')

  headerValues.forEach(function (headerValue) {
    const cellNode = document.createElement('th')
    cellNode.innerHTML = headerValue

    rowNode.appendChild(cellNode)
  })
  tableNode.appendChild(rowNode)

  // Show 1 row per form kind
  Object.keys(formKinds).forEach(function (oneFormKind) {
    const thisFormKind = formKinds[oneFormKind]

    const row = []
    row.push(thisFormKind.type || '-')
    row.push(thisFormKind.productionLine)
    row.push(thisFormKind.workstationForm)
    row.push(thisFormKind.times.min)
    row.push(thisFormKind.times.max)
    row.push(thisFormKind.users.length)
    row.push(thisFormKind.number)

    // Create row Node
    const rowNode = document.createElement('tr')

    row.forEach(function (oneRowCell) {
      const cellNode = document.createElement('td')
      cellNode.innerHTML = oneRowCell

      rowNode.appendChild(cellNode)
    })

    // Link to create view
    const cellNode = document.createElement('td')
    const linkNode = document.createElement('a')
    const urlParams = []
    urlParams.push(thisFormKind.type || '-')
    urlParams.push(thisFormKind.productionLine)
    urlParams.push(thisFormKind.workstationForm)

    linkNode.setAttribute('href', '/app/forms/explore/' + urlParams.join('/'))
    linkNode.innerText = 'Explore Data'
    cellNode.appendChild(linkNode)
    rowNode.appendChild(cellNode)

    // Link to iOS form creation
    const cellNodeLink = document.createElement('td')
    const linkNodeLink = document.createElement('a')
    const baseUrlToiOS = configVar.creationUrlIOS
    const urlToiOS = [
      baseUrlToiOS,
      thisFormKind.type,
      thisFormKind.workstationForm,
      'create'
    ].join('/')
    linkNodeLink.setAttribute('href', urlToiOS)
    linkNodeLink.setAttribute('target', '_blank')
    linkNodeLink.innerText = 'Open iOS form'
    cellNodeLink.appendChild(linkNodeLink)
    rowNode.appendChild(cellNodeLink)

    // Add row to table
    tableNode.appendChild(rowNode)
  })

  const containerNode = document.getElementById(containerID)

  containerNode.appendChild(tableNode)
}
