import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

import * as exportFeedPrep from '../csv-exports/feed-prep.js'
import * as exportMaterialReceiving from '../csv-exports/material-receiving.js'

import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'

import * as inventoryHelpers from './helpers.js'
import * as inventoryListeners from './listeners.js'
import * as inventoryOverallCharts from './overall-charts.js'
import * as inventoryMonthlyCharts from './monthly-charts.js'
import * as inventoryMaterialsCharts from './material-charts.js'

import htmlIndex from './index.html'

export async function show () {
  // console.log('module show')

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const feedUsageRecords = await exportFeedPrep.feedPrepData()
  const materialReceived = await exportMaterialReceiving.materialReceivingData()
  // console.log('feedUsageRecords', feedUsageRecords)
  // console.log('materialReceived', materialReceived)

  analyseMaterialReceived({
    materialReceived
  })

  const analysedData = parseAndGroupRecords({
    feedUsageRecords,
    materialReceived
  })
  const firstDay = analysedData.firstDay

  const timeframe = helpersTimeseries.generateTimeframe({
    minDay: firstDay,
    periodicity: 'd'
  })
  // console.log('timeframe', timeframe)

  const flowsPerIngredient = analyseFlowPerIngredient({
    timeframe,
    recordsByDay: analysedData.recordsByDay
  })

  inventoryHelpers.setChartParams({
    flowsPerIngredient,
    timeframe
  })

  inventoryMaterialsCharts.showIngredientCharts()

  inventoryOverallCharts.showRunningInventoryChart()
  inventoryOverallCharts.showUsageChart()

  inventoryMonthlyCharts.showMonthlyUsageChart()

  inventoryListeners.listenToStartDateSelector()
  inventoryListeners.listenToEndDateSelector()
  inventoryListeners.listenToTimeframeShortcut()
}

/*
  Group by day
  usableTimestamp

  // Make a timeframe of all days
  // make array of values for each day, for materials
  // receiving
  // nursery
  // breeding
  // farming

  {
    pkm: {
      receiving: []
      nursery: []
    }
  }
*/

function parseAndGroupRecords (params) {
  const analysedData = {
    firstDay: false,
    recordsByDay: {}
  }

  const feedUsageRecords = params.feedUsageRecords
  const materialReceived = params.materialReceived

  feedUsageRecords.forEach(function (oneRecord) {
    const recordDay = oneRecord.usableTimestamp.substring(0, 10)
    if (!analysedData.firstDay || analysedData.firstDay > recordDay) { analysedData.firstDay = recordDay }

    analysedData.recordsByDay[recordDay] =
      analysedData.recordsByDay[recordDay] || []
    analysedData.recordsByDay[recordDay].push(oneRecord)
  })

  materialReceived.forEach(function (oneRecord) {
    const recordDay = oneRecord.usableTimestamp.substring(0, 10)
    if (!analysedData.firstDay || analysedData.firstDay > recordDay) { analysedData.firstDay = recordDay }

    analysedData.recordsByDay[recordDay] =
      analysedData.recordsByDay[recordDay] || []
    analysedData.recordsByDay[recordDay].push(oneRecord)
  })

  return analysedData
}

function analyseMaterialReceived (params) {
  const materialReceived = params.materialReceived

  const materials = new Set()

  materialReceived.forEach(function (oneRecord) {
    materials.add(oneRecord.material)
  })

  // console.log('materials', materials)
}

//
//
function analyseFlowPerIngredient (params) {
  // console.log('analyseFlowPerIngredient', params)
  const timeframe = params.timeframe
  const recordsByDay = params.recordsByDay

  const flowsPerIngredient = prepareObject({
    timeframe
  })

  timeframe.forEach(function (oneDay, timeframeIndex) {
    if (!recordsByDay[oneDay]) return

    recordsByDay[oneDay].forEach(function (oneRecord) {
      analyseRecord({
        oneRecord,
        flowsPerIngredient,
        timeframeIndex
      })
    })
  })

  calculateRunningInventory({
    flowsPerIngredient,
    timeframe
  })
  calculateDaysOfInventory({
    flowsPerIngredient,
    timeframe
  })

  // console.log('flowsPerIngredient', flowsPerIngredient)
  return flowsPerIngredient
}

function prepareObject (params) {
  const flowsPerIngredient = {}
  const timeframe = params.timeframe

  Object.keys(exportFeedPrep.feedPropertyDetails).forEach(function (
    feedPropertyName
  ) {
    // // console.log(feedPropertyName)
    const ingredientName = getIngredientName(feedPropertyName)
    // Array(timeframe.length).fill([]), does not work becuase it uses the same [] for all
    flowsPerIngredient[ingredientName] = flowsPerIngredient[ingredientName] || {
      receiving: {
        values: Array(timeframe.length).fill(0),
        records: []
      },
      nursery: {
        values: Array(timeframe.length).fill(0),
        records: []
      },
      breeding: {
        values: Array(timeframe.length).fill(0),
        records: []
      },
      farming: {
        values: Array(timeframe.length).fill(0),
        records: []
      },
      totalUsage: {
        values: Array(timeframe.length).fill(0)
      },
      adjustments: {
        values: Array(timeframe.length).fill(0)
      },
      dailyChange: {
        values: Array(timeframe.length).fill(0)
      },
      runningInventory: {
        values: Array(timeframe.length).fill(0)
      },
      daysOfInventoryEstimate: {
        values: Array(timeframe.length).fill(0),
        setpoints: Array(timeframe.length).fill(0),
        occurencePerDay: Array(timeframe.length).fill(0)
      }
    }
  })

  return flowsPerIngredient
}

function paramsOfForm (oneRecord) {
  const formParams = {
    flowType: false,
    multiplier: -1,
    includeInUsage: false,
    occurencePerDay: 1
  }
  if (oneRecord.workstationForm === 'nursery-feedstock-preparation') {
    formParams.flowType = 'nursery'
    formParams.includeInUsage = true
  } else if (
    oneRecord.productionLine === 'breeding' &&
    oneRecord.workstationForm === 'grow-out-feedstock-preparation'
  ) {
    formParams.flowType = 'breeding'
    formParams.includeInUsage = true
  } else if (
    oneRecord.productionLine === 'farming' &&
    oneRecord.workstationForm === 'grow-out-feedstock-preparation'
  ) {
    formParams.flowType = 'farming'
    formParams.includeInUsage = true
    // formParams.occurencePerDay = 12
  } else if (oneRecord.workstationForm === 'material-receiving-form') {
    formParams.flowType = 'receiving'
    formParams.multiplier = 1
  }
  return formParams
}

function analyseRecord (params) {
  const oneRecord = params.oneRecord
  const flowsPerIngredient = params.flowsPerIngredient
  const timeframeIndex = params.timeframeIndex

  const formParams = paramsOfForm(oneRecord)

  const flowType = formParams.flowType
  const multiplier = formParams.multiplier
  const includeInUsage = formParams.includeInUsage

  Object.keys(exportFeedPrep.feedPropertyDetails).forEach(function (
    feedPropertyName
  ) {
    if (flowType !== 'receiving' && !oneRecord[feedPropertyName]) return
    if (flowType === 'receiving' && oneRecord.material !== feedPropertyName) { return }

    const ingredientName = getIngredientName(feedPropertyName)

    // Not clean: value might be overrode by a record
    if (
      isSetpoint(feedPropertyName) &&
      ['breeding', 'farming'].includes(flowType)
    ) {
      const setpointValue = getSetpointValue({
        oneRecord,
        feedPropertyName
      })
      flowsPerIngredient[ingredientName].daysOfInventoryEstimate.setpoints[
        timeframeIndex
      ] = setpointValue
      // flowsPerIngredient[ingredientName].daysOfInventoryEstimate.occurencePerDay[timeframeIndex] = formParams.occurencePerDay
      return
    }

    const flowValue = getFlowValue({
      oneRecord,
      feedPropertyName
    })

    const flowValueImpact = multiplier * flowValue
    flowsPerIngredient[ingredientName][flowType].values[
      timeframeIndex
    ] += flowValueImpact

    flowsPerIngredient[ingredientName][flowType].records[timeframeIndex] =
      flowsPerIngredient[ingredientName][flowType].records[timeframeIndex] || []
    flowsPerIngredient[ingredientName][flowType].records[timeframeIndex].push(
      oneRecord
    )

    flowsPerIngredient[ingredientName].dailyChange.values[
      timeframeIndex
    ] += flowValueImpact

    if (includeInUsage) {
      flowsPerIngredient[ingredientName].totalUsage.values[
        timeframeIndex
      ] += flowValueImpact
    }
  })
}

function getIngredientName (feedPropertyName) {
  return exportFeedPrep.feedPropertyDetails[feedPropertyName][1]
}

function isSetpoint (feedPropertyName) {
  return exportFeedPrep.feedPropertyDetails[feedPropertyName][0]
}

function getFlowValue (params) {
  const feedPropertyName = params.feedPropertyName
  const oneRecord = params.oneRecord
  if (oneRecord.workstationForm === 'material-receiving-form') {
    return oneRecord.totalQuantity
  } else {
    return oneRecord[feedPropertyName]
  }
}

function getSetpointValue (params) {
  const feedPropertyName = params.feedPropertyName
  const oneRecord = params.oneRecord
  if (oneRecord.workstationForm === 'material-receiving-form') {
    return false
  } else {
    return oneRecord[feedPropertyName]
  }
}

function calculateRunningInventory (params) {
  // console.log('calculateRunningInventory', params)
  const flowsPerIngredient = params.flowsPerIngredient
  const timeframe = params.timeframe

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    // // console.log('oneIngredient', oneIngredient)
    let runningInventory = 0

    timeframe.forEach(function (oneDay, timeframeIndex) {
      const flowValueImpact =
        flowsPerIngredient[oneIngredient].dailyChange.values[timeframeIndex]
      runningInventory += flowValueImpact

      if (Number.isFinite(feedStockTake[oneIngredient]?.[oneDay])) {
        const adjustment =
          feedStockTake[oneIngredient][oneDay] - runningInventory
        flowsPerIngredient[oneIngredient].adjustments.values[
          timeframeIndex
        ] = adjustment
        runningInventory = feedStockTake[oneIngredient][oneDay]
      }

      flowsPerIngredient[oneIngredient].runningInventory.values[
        timeframeIndex
      ] = runningInventory
      // // console.log(oneDay, flowValueImpact, runningInventory)
    })
  })
}

function calculateDaysOfInventory (params) {
  const flowsPerIngredient = params.flowsPerIngredient
  const timeframe = params.timeframe

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    // // console.log('oneIngredient', oneIngredient)
    let daysOfUsage = 0

    timeframe.forEach(function (oneDay, timeframeIndex) {
      const runningInventory =
        flowsPerIngredient[oneIngredient].runningInventory.values[
          timeframeIndex
        ]

      const days = 7
      const usageRange = {
        firstIndex: Math.max(0, timeframeIndex - days),
        lastIndex: timeframeIndex - 1
      }
      const valuesRange = flowsPerIngredient[
        oneIngredient
      ].totalUsage.values.slice(usageRange.firstIndex, usageRange.lastIndex)
      // Consider only days when there is a usage; ie. if there is a day off, it does not drop the average
      const usageValues = []
      valuesRange.forEach(function (oneValue) {
        if (Number.isFinite(oneValue) && oneValue < 0) {
          usageValues.push(oneValue)
        }
      })
      const rolling7day = -1 * d3.mean(usageValues)

      // const usageSetpoint = flowsPerIngredient[oneIngredient].daysOfInventoryEstimate.setpoints[timeframeIndex]

      if (Number.isFinite(runningInventory) && Number.isFinite(rolling7day)) {
        daysOfUsage = runningInventory / rolling7day
      }

      if (!Number.isFinite(daysOfUsage)) return
      // // console.log(oneIngredient, oneDay, runningInventory, rolling7day, daysOfUsage)
      flowsPerIngredient[oneIngredient].daysOfInventoryEstimate.values[
        timeframeIndex
      ] = daysOfUsage
    })
  })
}

// https://docs.google.com/spreadsheets/d/1uytKC8VCKeUnnWPq7DRi3jXW47nBHWCFrNAH7A1zWiA/edit#gid=870691175
const feedStockTake = {
  pkm: {
    '2021-12-21': 8713,
    '2021-12-28': 18000,
    '2021-12-31': 10500,
    '2022-01-07': 13000,
    '2022-01-14': 18000,
    '2022-01-21': 12000,
    '2022-01-28': 18000,
    '2022-02-04': 11000,
    '2022-02-11': 15000,
    '2022-02-18': 8000,
    '2022-02-25': 11000,
    '2022-03-03': 13000,
    '2022-03-10': 14000,
    '2022-03-17': 3000,
    '2022-03-24': 12800,
    '2022-03-31': 22800,
    '2022-04-07': 13000,
    '2022-04-14': 15200,
    '2022-05-05': 13500,
    '2022-05-12': 19300,
    '2022-05-19': 15600,
    // '2022-06-01': 7200,
    '2022-06-09': 11000,
    '2022-06-16': 5000,
    '2022-09-08': 9000
  },
  'fine rice husk': {
    '2021-12-21': 1139,
    '2021-12-28': 7000,
    '2021-12-31': 4411,
    '2022-01-07': 3200,
    '2022-01-14': 6300,
    '2022-01-21': 3900,
    '2022-01-28': 7000,
    '2022-02-04': 5500,
    '2022-02-11': 3500,
    '2022-02-18': 6250,
    '2022-02-25': 3500,
    '2022-03-03': 3000,
    '2022-03-08': 1000, // Rashvin stock take when thinking we have no more FRH
    '2022-03-17': 3300,
    '2022-03-24': 550,
    '2022-03-31': 5500,
    '2022-04-07': 25300,
    '2022-04-14': 22550,

    '2022-05-12': 14850,
    '2022-05-19': 13200,
    '2022-06-02': 9350,
    '2022-06-09': 13750,
    '2022-06-16': 11000,
    '2022-09-08': 5000
  },
  'wheat bran': {
    '2021-12-21': 1295,
    '2021-12-28': 770,
    '2021-12-31': 1715,
    '2022-01-07': 1190,
    // '2022-01-14': 1855, // Seems incorrect; corrected the following week
    '2022-01-21': 1855,
    // '2022-01-28': 1120,
    '2022-02-04': 2590,
    '2022-02-11': 2030,
    '2022-02-18': 980,
    '2022-02-25': 280,
    '2022-03-03': 1610,
    '2022-03-10': 630,
    '2022-03-17': 700,
    '2022-03-24': 665,
    '2022-03-31': 245,
    '2022-04-07': 22575,
    '2022-04-14': 20615,
    '2022-05-12': 8400,
    '2022-05-19': 5740,
    '2022-06-02': 3780,
    '2022-06-09': 18795,
    '2022-06-16': 14175,
    '2022-09-08': 23430
  },
  'chicken feed': {
    '2021-12-21': 1000,
    '2021-12-28': 2000,
    '2021-12-31': 2200,
    '2022-01-07': 1700,
    '2022-01-14': 900,
    '2022-01-21': 2300,
    '2022-01-28': 2000,
    '2022-02-04': 1400,
    '2022-02-11': 2450,
    '2022-02-18': 1950,
    '2022-02-25': 1400,
    '2022-03-03': 800,
    '2022-03-10': 2250,
    '2022-03-17': 1600,
    '2022-03-24': 1050,
    '2022-03-31': 2700,
    '2022-04-07': 1900,
    '2022-04-14': 1600,
    '2022-05-12': 1750,
    '2022-05-19': 1300,
    '2022-06-02': 2250,
    '2022-06-09': 1200,
    '2022-06-16': 650,
    '2022-09-08': 750
  },
  exuvia: {
    '2021-12-21': 420
  },
  'fish pallet': {
    '2022-02-18': 7500
  },
  'fish feed': {
    '2022-02-18': 2000
  },
  'fish meal': {
    '2022-02-18': 300
  },
  'krill meal': {
    '2022-02-18': 1000
  },
  'wheat flour': {
    '2022-03-08': 0
  }
}
