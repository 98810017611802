import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

// import * as exportFeedPrep from '../csv-exports/feed-prep.js'
import * as dataFrassActivity from './data-load.js'

import * as subModPatternManipulation from './pattern-manipulation.js'
import * as subModPatternDrawing from './pattern-drawing.js'
import * as subModPatternListener from './pattern-events.js'

import htmlIndex from './index.html'

export async function show () {
  // console.log('module show')
  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  console.time('loadData')
  const frassActivityData = await dataFrassActivity.loadData()
  console.timeEnd('loadData')
  // console.log('frassActivityData', frassActivityData)

  const patternData = subModPatternManipulation.createPatternData({
    records: frassActivityData
  })

  subModPatternDrawing.showPattern({
    patternData
  })

  subModPatternListener.cellListener({
    patternData
  })
}
