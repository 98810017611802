import { Chart, registerables } from 'chart.js'

import * as helpersTimeseries from '../timeseries/timeseries.js'
import { chartInstances } from './instances.js'

import * as configVar from '../../config.js'
Chart.register(...registerables)

/*
    @params
        .timeframe []
        .metricNames []
*/
export function drawTrendCharts (params = {}) {
  // console.log('drawTrendCharts', params)
  const chartParams = params.chartParams
  const metricNames = chartParams.metricNames

  metricNames.forEach(function (oneMetricName) {
    drawChart({
      metricName: oneMetricName,
      dailyTargets: chartParams.dailyTargets,
      highlights: chartParams.highlights,
      timeframe: chartParams.timeframe,
      metricsDB: chartParams.metricsDB,
      valueSeries: chartParams.valueSeries,
      dates: chartParams.dates
    })
  })
}

/*
  @params
    .chartContainerId
    .chartValues: { dates: [], values: [] }
*/
function drawChart (params = {}) {
  // // console.log('drawChart', params)
  const metricName = params.metricName
  const metricsDB = params.metricsDB
  const metricDB = metricsDB[metricName]
  const dailyTargets = params.dailyTargets
  const highlights = params.highlights || {}

  if (!metricDB) return

  let isShowTarget = true
  // Allow to pass the parameter
  if (typeof params.isShowTarget === 'boolean') { isShowTarget = params.isShowTarget }

  const chartTitle = 'Trend for: ' + metricName

  const config = {
    type: 'bar',
    options: {
      maintainAspectRatio: false, // Fill the container size
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          }
        },
        y: {
          title: {
            display: true,
            text: metricDB.unit
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: chartTitle
        },
        legend: {
          position: 'bottom'
        },
        autocolors: {
          enabled: false
        }
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      onClick: clickBehaviour,
      appContext: {
        metricsDB: params.metricsDB,
        timeframe: params.timeframe,
        chartParams: params
      }
    }
  }

  const chartContainerId = 'c_' + metricName
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  let dateTimeframe = params.timeframe
  const firstIndex = helpersTimeseries.getFirstIndex(params)
  const lastIndex = helpersTimeseries.getLastIndex(params)

  dateTimeframe = dateTimeframe.slice(firstIndex, lastIndex)

  // Set the Chart to have such xAxis labels
  const chartData = {
    labels: dateTimeframe,
    datasets: []
  }

  params.valueSeries.forEach(function (oneValueSerie) {
    if (typeof oneValueSerie === 'object') {
      chartData.datasets.push({
        label: [metricName, oneValueSerie.series].join(' '),
        data: metricDB[oneValueSerie.series].slice(firstIndex, lastIndex),
        type: oneValueSerie.type,
        // backgroundColor: '#4285f4',
        borderColor: '#4285f4',
        pointRadius: 1,
        order: 0 // 0 to draw first, and be at the top of the chart; other dataset see to have an order too
      })
    } else {
      // Color highlights
      const dataValues = metricDB[oneValueSerie].slice(firstIndex, lastIndex)
      let colors = '#bed2f4'
      if (highlights[metricName]) {
        colors = []
        dataValues.forEach(function (oneValue) {
          if (
            oneValue >= highlights[metricName].good?.above ||
            oneValue <= highlights[metricName].good?.below
          ) {
            colors.push('#B2E8D1')
          } else if (
            oneValue >= highlights[metricName].bad?.above ||
            oneValue <= highlights[metricName].bad?.below
          ) {
            colors.push('#F1CAC1')
          } else {
            colors.push('#bed2f4')
          }
        })
      }
      chartData.datasets.push({
        label: metricName,
        data: dataValues,
        type: 'bar',
        backgroundColor: colors,
        order: 1
      })
    }
  })

  // Show the daily target
  if (isShowTarget) {
    if (dailyTargets[metricName]) {
      const targetValues = Array(dateTimeframe.length).fill(
        dailyTargets[metricName]
      )
      chartData.datasets.push({
        label: 'Daily Target',
        data: targetValues,
        type: 'line',
        radius: 0,
        borderColor: 'rgba(247,177,24,0.5)',
        backgroundColor: 'rgba(247,177,24,0.5)',
        order: 1
      })
    }
  }

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}

function clickBehaviour (clickEvent, element) {
  // console.log('clickBehaviour', clickEvent, element)
  const clickedXindex = element[0].index
  const chartInstance = clickEvent.chart

  const appContext = chartInstance.config.options.appContext

  const clickedLabel = clickEvent.chart.data.labels[clickedXindex]
  console.log(clickedLabel)

  const chartParams = appContext.chartParams
  const firstIndex = helpersTimeseries.getFirstIndex(chartParams)

  const dailySeriesIndex = firstIndex + clickedXindex

  const chartMetricName = chartParams.metricName
  const metricDB = chartParams.metricsDB[chartMetricName]

  const records = metricDB.records[dailySeriesIndex]
  // console.log(dailySeriesIndex)
  // console.log(records)

  // Show the record in a modal, with link to iOS
  const highlightElement = document.getElementById('recordHighlights')
  highlightElement.innerHTML = '' // clean

  const baseUrlToiOS = configVar.baseUrlToiOS
  records.forEach(function (oneRecord) {
    const urlToRecord = [
      baseUrlToiOS,
      oneRecord.productionLine,
      oneRecord.workstationForm,
      'edit',
      oneRecord._id
    ].join('/')
    // // console.log(urlToRecord)
    const oneLinkElement = document.createElement('a')
    oneLinkElement.setAttribute('href', urlToRecord)
    oneLinkElement.setAttribute('target', '_blank')
    oneLinkElement.innerText = [
      oneRecord.productionLine,
      oneRecord.workstationForm,
      'edit',
      oneRecord._id
    ].join('/')
    highlightElement.append(oneLinkElement)
  })

  highlightElement.classList.remove('hide')

  // Eg. deeplink:
  // process-tracker/attendance/edit/9b624f1b51bf2093064166cc1a1d94e8
}
