import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

export function loadAndexportCsv () {
  // console.log('loadAndexportCsv')
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'csv_exports', 'attendance'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi: ', urlApi)
  console.time('loadAndexportCsv')
  fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      console.timeEnd('loadAndexportCsv')
      // console.log('api loaded', urlApi)
      // console.log(data)
      // const recordValues = helpersCsvExports.couchRecordsToArrayValues(data)
      const recordValues = data.data
      const csvFormatted = d3.csvFormat(recordValues)
      helpersCsvExports.downloadCsv(csvFormatted)
    })
}

/*
  Run this in the browser and you will receive a 401 if not logged or logged but not authorised
*/
// function authorisationTest () {
//   const urlApi = ['/api', 'csv_exports', 'attendance'].join('/')
//   const fetchOptionsApi = {
//     method: 'GET'
//   }
//   // console.log('urlApi', urlApi)
//   fetch(urlApi, fetchOptionsApi)
//     .then(response => response.json())
//     .then(function (data) {
//       // console.log('api loaded', urlApi)
//       // console.log(data)
//     })
// }
