import accounting from 'accounting'

import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

// import * as exportFeedPrep from '../csv-exports/feed-prep.js'
import * as exportRackSetupHarvest from '../csv-exports/rack-setup-harvest.js'

import * as subModFarmingGC from './growout-setup-harvest.js'

import htmlIndex from './index.html'

export async function show () {
  // console.log('module show')

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  console.time('loadData')
  const rackSetupHarvest = await exportRackSetupHarvest.loadData()
  console.timeEnd('loadData')
  // console.log('rackSetupHarvest', rackSetupHarvest)

  console.time('preparePattern')
  const tableContainer = subModFarmingGC.showFarmingGC({
    records: rackSetupHarvest,
    baseFilters: activePatternFilter()
  })
  console.timeEnd('preparePattern')

  const patternContainerElement = document.getElementById('GCpattern')
  if (!patternContainerElement) return
  patternContainerElement.appendChild(tableContainer)

  console.time('drawDOM')
  showPatternFilters()
  console.timeEnd('drawDOM')
  cellListener()
}

function cellListener () {
  const patternContainerElement = document.getElementById('GCpattern')
  if (!patternContainerElement) return

  patternContainerElement.addEventListener('click', function (clickEvent) {
    const patternCellElement = clickEvent.target.closest('.patternCell')
    if (!patternCellElement) return

    const cellDay = patternCellElement.getAttribute('day')
    const cellLine = patternCellElement.getAttribute('line')
    // console.log(cellDay, cellLine)

    const records = subModFarmingGC.patternData.recordsSetupHarvest
    const cellRecords = subModFarmingGC.findCellRecords({
      oneDay: cellDay,
      oneLine: cellLine,
      records,
      baseFilters: activePatternFilter()
    })
    // console.log(cellRecords)
    showCellDetails({
      cellRecords
    })
  })
}

/*
  Show Setup and Harvest Details
*/
function showCellDetails (params) {
  const cellRecords = params.cellRecords

  // Show the record in a modal, with link to iOS
  const highlightsContainerElement = document.getElementById('recordHighlights')
  highlightsContainerElement.innerHTML = '' // clean

  // Setups
  showRackHighlights({
    cellRecords,
    recordType: 'setupRacks'
  })
  showRackHighlights({
    cellRecords,
    recordType: 'harvestRacks'
  })

  highlightsContainerElement.classList.remove('hide')
}

function showRackHighlights (params) {
  const cellRecords = params.cellRecords
  const highlightsContainerElement = document.getElementById('recordHighlights')

  const records = cellRecords[params.recordType]
  if (records.length === 0) return

  const headerElement = document.createElement('h1')

  const title = []
  title.push(records.length)

  if (params.recordType === 'setupRacks') {
    title.push(' Rack[S] Setup')
  } else {
    title.push(' Rack[S] Harvested')
  }

  if (records.length > 1) {
    title[1] = title[1].replace('[S]', 's')
  } else {
    title[1] = title[1].replace('[S]', '')
  }
  headerElement.innerText = title.join('')
  highlightsContainerElement.appendChild(headerElement)

  const highlightGridContainer = document.createElement('div')
  highlightGridContainer.classList.add('cssGrid')

  let rowIndex = 0

  // Headers
  let columnIndex = 0
  rowIndex++
  fields.forEach(function (oneField, fieldIndex) {
    // Always show the headers, allowing to keep table layout constant
    // if (oneField.filter && oneField.filter !== params.recordType) return
    columnIndex++

    const columnTitle = oneField.title ? oneField.title : oneField.propertyName

    const cellElement = document.createElement('div')
    cellElement.innerText = columnTitle
    cellElement.style['grid-row'] = rowIndex
    cellElement.style['grid-column'] = columnIndex
    cellElement.classList.add('headerCell')

    if (oneField.classes) {
      oneField.classes.forEach(function (oneClass) {
        cellElement.classList.add(oneClass)
      })
    }

    highlightGridContainer.appendChild(cellElement)
  })

  records.forEach(function (oneRecord, recordIndex) {
    rowIndex++

    let columnIndex = 0
    fields.forEach(function (oneField, fieldIndex) {
      // if (oneField.filter && oneField.filter !== params.recordType) return
      columnIndex++
      const cellElement = document.createElement('div')
      cellElement.style['grid-row'] = rowIndex
      cellElement.style['grid-column'] = columnIndex
      if (oneField.classes) {
        oneField.classes.forEach(function (oneClass) {
          cellElement.classList.add(oneClass)
        })
      }

      if (oneField.processingHtml) {
        const cellHtml = oneField.processingHtml(oneRecord)
        cellElement.appendChild(cellHtml)
      } else {
        const value = oneRecord[oneField.propertyName]
        let formattedValue = value
        if (oneField.processing) {
          formattedValue = oneField.processing(value)
        }
        cellElement.innerHTML = '<p>' + formattedValue + '</p>'
      }

      highlightGridContainer.appendChild(cellElement)
    })
  })
  highlightsContainerElement.append(highlightGridContainer)
}

const fields = [
  {
    propertyName: 'rackId',
    processing: function (value) {
      return value
    }
  },
  {
    propertyName: 's_id',
    title: 'setup',
    processingHtml: function (oneRecord) {
      const urlToRecord = [
        configVar.baseUrlToiOS,
        oneRecord.s_type,
        'grow-out-setup',
        'edit',
        oneRecord.s_id
      ].join('/')
      const oneLinkElement = document.createElement('a')
      oneLinkElement.setAttribute('href', urlToRecord)
      oneLinkElement.setAttribute('target', '_blank')
      oneLinkElement.innerText = 'open'
      return oneLinkElement
    }
  },
  {
    propertyName: 'h_id',
    title: 'harvest',
    processingHtml: function (oneRecord) {
      const urlToRecord = [
        configVar.baseUrlToiOS,
        oneRecord.s_type,
        'grow-out-harvest',
        'edit',
        oneRecord.h_id
      ].join('/')
      const oneLinkElement = document.createElement('a')
      oneLinkElement.setAttribute('href', urlToRecord)
      oneLinkElement.setAttribute('target', '_blank')
      oneLinkElement.innerText = 'open'
      return oneLinkElement
    }
  },
  {
    propertyName: 'rackAgeDays',
    title: 'Rack Age',
    filter: 'harvestRacks',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 1, ',', '.') + ' days'
      }
      return ''
    }
  },
  {
    propertyName: 'rackBCE',
    title: 'BCE Est.',
    filter: 'harvestRacks',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(100 * value, 1, ',', '.') + '%'
      }
      return ''
    }
  },
  {
    propertyName: 'h_larvae',
    title: 'Larvae',
    filter: 'harvestRacks',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 1, ',', '.') + 'Kg'
      }
      return ''
    }
  },
  {
    propertyName: 'h_larvaeAverageWeight',
    title: 'Larvae Av. Weight',
    filter: 'harvestRacks',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(1000 * value, 1, ',', '.') + 'mg'
      }
      return ''
    }
  },
  {
    propertyName: 'h_frass',
    title: 'Frass',
    filter: 'harvestRacks',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 1, ',', '.') + 'Kg'
      }
      return ''
    }
  },
  {
    propertyName: 's_feedPerSeedlings',
    title: 'Feed',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 0, ',', '.') + 'g/g'
      }
      return ''
    }
  },

  {
    propertyName: 's_feedstock',
    title: 'Substrate',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 0, ',', '.') + 'Kg'
      }
      return ''
    }
  },
  {
    propertyName: 's_seedlingsWeightRack',
    title: 'Seedlings',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 0, ',', '.') + 'g'
      }
      return ''
    }
  },
  {
    propertyName: 's_seedlingsVolume',
    title: 'S. per Tray',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 0, ',', '.') + 'g'
      }
      return ''
    }
  },
  {
    propertyName: 's_5DOL',
    title: 'Seedlings',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(value, 0, ',', '.')
      }
      return ''
    }
  },
  {
    propertyName: 's_5DOLarvaeWeight',
    title: 'Seedlings Av. Weight',
    classes: ['right'],
    processing: function (value) {
      if (Number.isFinite(value)) {
        return accounting.formatNumber(1000 * value, 1, ',', '.') + 'mg'
      }
      return ''
    }
  },
  {
    propertyName: 's_by',
    title: 'By'
  },
  {
    propertyName: 's_type',
    title: 'Rack Type'
  },
  {
    propertyName: 's_trays',
    classes: ['right'],
    title: 'Trays'
  }
]

const patternFilters = [
  {
    title: 'breeding',
    filterOn: 'type',
    value: 'breeding'
  },
  {
    title: 'farming',
    filterOn: 'type',
    value: 'farming',
    active: true
  },
  {
    title: 'same day harvest',
    filterOn: 'harvestAge',
    value: 'sameDay'
  }
]

// Return the current active filter
function activePatternFilter () {
  // console.log('activePatternFilter', patternFilters)
  const patternFilterChosen = patternFilters.filter(function (oneFilter) {
    return oneFilter.active
  })
  // console.log('patternFilterChosen', patternFilterChosen)
  return patternFilterChosen
}

//
function showPatternFilters () {
  const filtersContainerElement = document.getElementById('patternFilter')
  if (!filtersContainerElement) return

  patternFilters.forEach(function (onePatternFilter) {
    const filterButton = document.createElement('div')
    filterButton.setAttribute('filterTitle', onePatternFilter.title)
    filterButton.classList.add('filterButton')
    filterButton.classList.add('layoutFilterTag')

    if (onePatternFilter.active !== true) {
      filterButton.classList.add('disabled')
    }
    filterButton.innerText = onePatternFilter.title

    filtersContainerElement.appendChild(filterButton)
  })

  filtersContainerElement.addEventListener('click', function (clickEvent) {
    const buttonClicked = clickEvent.target.closest('.filterButton')
    if (!buttonClicked) return

    const filterTitle = buttonClicked.getAttribute('filterTitle')

    // Deactive filters
    patternFilters.forEach(function (onePatternFilter) {
      onePatternFilter.active = false
      const htmlElement = filtersContainerElement.querySelector(
        '.filterButton[filterTitle="' + onePatternFilter.title + '"]'
      )
      htmlElement.classList.add('disabled')
    })

    const patternFilterChosen = patternFilters.filter(function (oneFilter) {
      return oneFilter.title === filterTitle
    })
    // console.log('patternFilterChosen', patternFilterChosen)
    patternFilterChosen.forEach(function (oneFilter) {
      oneFilter.active = true
    })
    buttonClicked.classList.remove('disabled')

    const patternContainerElement = document.getElementById('GCpattern')
    // console.log('patternContainerElement', patternContainerElement)
    if (!patternContainerElement) return
    patternContainerElement.innerHTML = '' // reset

    const patternData = subModFarmingGC.patternData

    const tableContainer = subModFarmingGC.generatePatternContent({
      timeframe: patternData.timeframe,
      gcLines: patternData.gcLines,
      records: patternData.recordsSetupHarvest,
      baseFilters: patternFilterChosen
    })
    // console.log('tableContainer', tableContainer)

    patternContainerElement.appendChild(tableContainer)
  })
}
