import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

const urlApi = [configVar.apiRoutes, 'data', 'form-records'].join('/')

export async function loadAndexportCsv () {
  // console.log('loadAndexportCsv')

  const exportArray = await loadApiData()
  const csvFormatted = d3.csvFormat(exportArray)
  helpersCsvExports.downloadCsv(csvFormatted)
}

export async function recordsData () {
  // console.log('recordsData')
  const records = await loadApiData({
    sort: 1
    // forceProperties: ['_id']
  })

  // records.forEach(function (oneRecord, arrayIndex) {
  //   calculateUsageInfo({
  //     records,
  //     arrayIndex
  //   })
  // })

  // console.log(records)

  return records
}

// Store in memory
// let feedRecords

async function loadApiData (params = {}) {
  // Calling backend
  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  return fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      // console.log('api loaded', urlApi)
      // console.log(data)
      const recordValues = data.data

      // const recordsData = viewRecords(recordValues)
      sortRecords(recordValues, params)

      // feedRecords = recordsData
      // // console.log('recordsData', recordsData)

      // const properties = identifyAllProperties({
      //   array: recordsData,
      //   path: 'formData'
      // })
      // const propertiesToExport = propertiesForExport(properties, params)

      // const exportArray = contentForExport(recordsData, propertiesToExport)
      // // console.log('exportArray', exportArray)

      return recordValues
    })
}

//
//
// function viewRecords (data) {
//   const recordsData = []
//   data.forEach(function (oneRecord) {
//     // Apply filter if needed
//     recordsData.push(oneRecord)
//   })

//   return recordsData
// }

function sortRecords (recordsData, params) {
  const sort = params.sort || 1
  recordsData.sort(function (record1, record2) {
    const date1 = new Date(record1.usableTimestamp)
    const date2 = new Date(record2.usableTimestamp)
    return sort * (date1 - date2)
  })
}
