import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

import htmlIndex from './index.html'

//
export function show () {
  // console.log("module show");

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)
}
