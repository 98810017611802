import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'
import * as helperTime from '../../helpers/time.js'

import * as exportFeedPrep from '../csv-exports/feed-prep.js'

import htmlIndex from './index.html'

export function show () {
  // console.log('module show')

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showFeedUsage()
}

const DOMelementId = 'feedUsage'

async function showFeedUsage () {
  // console.log('showFeedUsage')
  const feedUsageRecords = await exportFeedPrep.feedPrepData()

  const DOMelement = document.getElementById(DOMelementId)

  // console.log('feedUsage', feedUsageRecords)

  const feedUsageTable = createTable(feedUsageRecords)
  DOMelement.appendChild(feedUsageTable)

  listenClicks(feedUsageRecords)
}

function createTable (feedUsageRecords) {
  // console.log('createTable', feedUsageRecords)
  const feedProperties = exportFeedPrep.feedProperties
  console.log('feedProperties', feedProperties)

  const analysedProperties = exportFeedPrep.analyseProperties({
    array: feedUsageRecords
  })
  // console.log('analysedProperties', analysedProperties)
  const feedPropertiesList = analysedProperties.feed.concat(
    analysedProperties.unclassified
  )

  const rowLeftColumns = [
    'timestamp',
    'timestampsDelta',
    'submission_timestamp',
    'c_minFromPrevious',
    'productionLine',
    'workstationForm',
    'user_name',
    'totals'
  ]

  const tableElement = document.createElement('div')
  tableElement.classList.add('tableLayout')
  tableElement.classList.add('feedTable')
  const rowElement = document.createElement('div')
  rowElement.classList.add('row')

  rowLeftColumns.forEach(function (oneColumn) {
    const oneColumnHeader = document.createElement('div')
    oneColumnHeader.classList.add('cell')
    oneColumnHeader.classList.add('header')
    oneColumnHeader.classList.add('left')
    if (oneColumn === 'totals') {
      oneColumnHeader.classList.add('wide')
    }
    oneColumnHeader.innerText = oneColumn
    rowElement.appendChild(oneColumnHeader)
  })

  // console.log('exportFeedPrep.feedPropertyDetails', exportFeedPrep.feedPropertyDetails)

  feedPropertiesList.forEach(function (oneFeedProperty) {
    // // console.log('oneFeedProperty', oneFeedProperty)
    const oneColumnHeader = document.createElement('div')
    oneColumnHeader.classList.add('cell')
    oneColumnHeader.classList.add('header')
    oneColumnHeader.classList.add('ingredient')
    if (exportFeedPrep.isSetpoint(oneFeedProperty)) {
      oneColumnHeader.classList.add('isSetpoint')
    }

    oneColumnHeader.innerText = oneFeedProperty
    rowElement.appendChild(oneColumnHeader)
  })
  tableElement.appendChild(rowElement)

  const baseUrlToiOS = configVar.baseUrlToiOS

  // Form Records
  feedUsageRecords.forEach(function (oneFeedPrepRecord, arrayIndex) {
    const rowElement = document.createElement('div')
    rowElement.classList.add('row')
    rowElement.setAttribute('recordId', oneFeedPrepRecord._id)

    rowLeftColumns.forEach(function (oneColumn) {
      if (oneColumn === 'totals') {
        rowElement.appendChild(cellTotals(oneFeedPrepRecord))
        return
      }
      const oneCell = document.createElement('div')
      oneCell.classList.add('cell')
      oneCell.classList.add('left')

      const urlToRecord = [
        baseUrlToiOS,
        oneFeedPrepRecord.productionLine,
        oneFeedPrepRecord.workstationForm,
        'edit',
        oneFeedPrepRecord._id
      ].join('/')
      const oneLinkElement = document.createElement('a')
      oneLinkElement.setAttribute('href', urlToRecord)
      oneLinkElement.setAttribute('target', '_blank')

      let cellText = oneFeedPrepRecord[oneColumn]

      if (['timestamp', 'submission_timestamp'].includes(oneColumn)) {
        cellText = formatTimestamp(oneFeedPrepRecord[oneColumn])
        if (oneColumn === 'timestamp') {
          cellText = cellText.substring(0, 16)
        }
        if (oneColumn === 'submission_timestamp') {
          // Only show the hour
          cellText = cellText.substring(11, 16)
        }
      } else if (oneColumn === 'usableTimestamp') {
        cellText = oneFeedPrepRecord[oneColumn].substring(0, 16)
      } else if (oneColumn === 'timestampsDelta') {
        // Turn positive
        cellText = -1 * oneFeedPrepRecord[oneColumn]
        cellText = cellText.toFixed(0) + 'sec'
      }

      oneLinkElement.innerText = cellText

      oneCell.appendChild(oneLinkElement)
      rowElement.appendChild(oneCell)
    })

    feedPropertiesList.forEach(function (oneFeedProperty) {
      const oneCell = document.createElement('div')
      oneCell.classList.add('cell')
      oneCell.classList.add('ingredient')

      if (exportFeedPrep.isSetpoint(oneFeedProperty)) {
        oneCell.classList.add('isSetpoint')
      }

      const weight = Number.isFinite(+oneFeedPrepRecord[oneFeedProperty])
        ? oneFeedPrepRecord[oneFeedProperty]
        : ''
      oneCell.innerText = weight
      rowElement.appendChild(oneCell)
    })
    tableElement.appendChild(rowElement)

    showDaySeparator(feedUsageRecords, arrayIndex, tableElement)
  })
  // console.log('tableElement', tableElement)
  return tableElement
}

function formatTimestamp (timestamp) {
  return helperTime.timestampValue(timestamp)
}

function showDaySeparator (feedUsageRecords, arrayIndex, tableElement) {
  // // console.log('showDaySeparator', arguments)
  const currentRecord = feedUsageRecords[arrayIndex]
  if (!currentRecord) return

  const nextRecord = feedUsageRecords[arrayIndex + 1]
  if (!nextRecord) return

  if (
    currentRecord?.usableTimestamp?.substring(0, 10) ===
    nextRecord?.usableTimestamp?.substring(0, 10)
  ) { return }

  const rowElement = document.createElement('div')
  rowElement.classList.add('row')
  rowElement.classList.add('daySeparator')

  tableElement.appendChild(rowElement)
}

function cellTotals (record) {
  const cellElement = document.createElement('div')
  cellElement.classList.add('cell')
  cellElement.classList.add('wide')
  cellElement.innerText = [
    record.c_totalWeight,
    'Kg / ',
    record.c_totalSetpoints
  ].join('')
  return cellElement
}

function listenClicks (feedUsageRecords) {
  const DOMelement = document.getElementById(DOMelementId)

  DOMelement.addEventListener('click', function (clickEvent) {
    const rowClicked = clickEvent.target.closest('div.row')
    if (!rowClicked) return

    const clickedId = rowClicked.getAttribute('recordId')

    const record = exportFeedPrep.findRecordById({
      _id: clickedId
    })
    console.log(record)

    // console.log(feedUsageRecords)
    const feedRecord = feedUsageRecords.find(function (oneRecord) {
      return oneRecord._id === clickedId
    })
    // console.log(feedRecord)
    exportFeedPrep.calculateUsageInfo(feedRecord)
  })
}
