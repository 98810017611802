import * as inventoryHelpers from './helpers.js'

import * as dataseriesModule from '../../helpers/dataseries/dataseries.js'
import * as chartsPeriods from '../../helpers/charts/periods.js'

//
//
export function showMonthlyUsageChart () {
  const chartParams = inventoryHelpers.chartParams

  const flowsPerIngredient = chartParams.flowsPerIngredient
  const timeframe = chartParams.timeframe

  const monthlyUsage = calculateMonthlyUsage({
    timeframe,
    flowsPerIngredient
  })

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    drawMaterialChart({
      monthlyUsage,
      ingredientName: oneIngredient,
      dates: chartParams.dates
    })
  })
}

function drawMaterialChart (params) {
  // // console.log('drawMaterialChart', params)
  const monthlyUsage = params.monthlyUsage
  const ingredientName = params.ingredientName
  const dates = params.dates

  const chartElementId = 'm_' + ingredientName
  const chartElementDOM = document.getElementById(chartElementId)
  if (!chartElementDOM) {
    const chartElement = document.createElement('div')
    chartElement.setAttribute('id', chartElementId)
    chartElement.classList.add('chart')

    const containerElement = document.getElementById('monthlyMaterialsUsage')
    containerElement.appendChild(chartElement)
  }

  chartsPeriods.drawPeriodChart({
    metricName: ingredientName,
    metricsDB: monthlyUsage,
    chartElementId,
    dates,
    valueMultiplier: -1
  })
}

//
function calculateMonthlyUsage (params) {
  // // console.log('calculateMonthlyUsage', params)
  const timeframe = params.timeframe
  const flowsPerIngredient = params.flowsPerIngredient

  const monthlyUsage = {}

  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    const data = flowsPerIngredient[oneIngredient].totalUsage
    // Show monthly values
    monthlyUsage[oneIngredient] = {
      mtdValues: []
    }
    monthlyUsage[oneIngredient].mtdValues = dataseriesModule.seriesMTD({
      dailyValues: data.values,
      timeframe
    })
  })

  // // console.log('monthlyUsage', monthlyUsage)
  return monthlyUsage
}
