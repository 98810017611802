export function downloadCsv (csv) {
  let csvContent = 'data:text/csv;charset=utf-8,'
  csvContent += csv
  // // console.log(csvContent)

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'exportedData.csv')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.csv".
}

export function couchRecordsToArrayValues (data) {
  const records = data.rows

  const recordValues = records.map(function (oneRecord) {
    const targetValues = oneRecord.value
    return targetValues
  })

  // // console.log(recordValues)
  return recordValues
}

export function analyseProperties (arrayOfObjects) {
  // console.log('analyse', arrayOfObjects)

  const properties = {}

  arrayOfObjects.forEach(function (oneObject) {
    Object.keys(oneObject).forEach(function (oneProperty) {
      properties[oneProperty] = properties[oneProperty] || {
        uniqueValues: new Set()
      }

      properties[oneProperty].uniqueValues.add(oneObject[oneProperty])
    })
  })

  // console.log(properties)
}
