import { round } from 'mathjs'

import { seriesRollingAggregation } from './dataseries.js'

//
export function generateRatioSeries (params) {
  // // console.log('generateRatioSeries', params)

  const timeframe = params.timeframe
  const metricsDB = params.metricsDB
  const metricNames = params.metricNames
  const unitMultipliers = params.unitMultipliers || [1, 1]
  const valueSeries = params.valueSeries // Which series to use: eg. dailyValues or r7d1
  const divisorLagDays = params.divisorLagDays || 0 // Days of delay between the dividends and divisors
  const ratioUnit = params.ratioUnit || '#'

  // if (
  // metricNames[0] === "eggsCollection" &&
  // metricNames[1] === "eggsCountPerGram"
  // ) {
  // console.log(metricsDB[metricNames[0]]);
  // console.log(metricsDB[metricNames[1]]);
  // }

  const dividends = metricsDB[metricNames[1]]?.[valueSeries]
  const divisors = metricsDB[metricNames[0]]?.[valueSeries]

  if (!dividends) return
  if (!divisors) return

  // Record the divisors values, adjusted for the delay of the dividens
  // Eg. Egg to Seelings: 5 days... so Eggs of D-5 will be used
  const divisorsLagged = metricsDB[metricNames[0]][valueSeries].slice()
  for (let x = 0; x < divisorLagDays; x++) {
    divisorsLagged.unshift(null)
  }
  // // console.log('divisorsLagged', divisorsLagged)

  let ratioValues = []

  timeframe.forEach(function (oneDay, arrayIndex) {
    // If the delay is not reached, we can not have a ratio value
    if (arrayIndex < divisorLagDays) {
      ratioValues.push(null)
      return
    }

    let dividend = dividends[arrayIndex]
    let divisor = divisorsLagged[arrayIndex]

    if (!Number.isFinite(divisor)) {
      ratioValues.push(null)
      return
    }

    if (divisor === 0) {
      ratioValues.push(null)
      return
    }

    if (!Number.isFinite(dividend)) {
      ratioValues.push(null)
      return
    }

    if (dividend === 0) {
      ratioValues.push(0)
      return
    }

    dividend = dividend * unitMultipliers[1]
    divisor = divisor * unitMultipliers[0]

    let value = dividend / divisor

    if (ratioUnit === '%') {
      value = 100 * value
    }
    value = round(value, 2)
    ratioValues.push(value)
  })

  if (params.rollingAggregation) {
    ratioValues = seriesRollingAggregation({
      rollingDays: params.rollingAggregation.rollingDays,
      rollingDivider: params.rollingAggregation.rollingDivider,
      valuesToAggregate: ratioValues
    })
  }
  return {
    ratioValues,
    metricNames,
    dividends,
    divisors,
    divisorsLagged,
    divisorLagDays,
    valueSeries,
    ratioUnit,
    rollingAggregation: params.rollingAggregation
  }
}
