import * as configVar from './config.js'
import * as appStarter from './app-starter.js'

import * as pageModuleProductionDashboard from './page-modules/production-dashboard/index.js'
import * as pageModuleCsvExport from './page-modules/csv-exports/index.js'
import * as pageModuleForms from './page-modules/forms/index.js'
import * as pageModuleFeed from './page-modules/feed/index.js'
import * as pageModuleInventoryFeed from './page-modules/inventory-feed/index.js'
import * as pageModuleFormRecords from './page-modules/records/index.js'
import * as pageModulePatterns from './page-modules/patterns/index.js'
import * as pageModuleFrass from './page-modules/frass/index.js'
import * as pageModuleQuality from './page-modules/quality/index.js'

// Set the base route urls
page.base(configVar.appRoutes)

page('/csv-export', async function (context) {
  // console.log('page csv-export')
  await appStarter.pageLoader()

  pageModuleCsvExport.show()
})

page('/dashboard-production', async function (context) {
  // console.log('page dashboard-production')
  await appStarter.pageLoader()

  pageModuleProductionDashboard.show()
})

page('/dashboard-production/update-couchdb', async function (context) {
  // console.log('page dashboard-production')
  await appStarter.pageLoader()

  pageModuleProductionDashboard.updateCouchDBview()
})

page('/forms', async function (context) {
  // console.log('page forms')
  // console.log(context)
  await appStarter.pageLoader()

  pageModuleForms.show()
})

page('/forms/:subpage', async function (context) {
  // console.log('page forms')
  // console.log(context)
  await appStarter.pageLoader()

  pageModuleForms.show(context.params)
})

page('/forms/:subpage/:type/:productionLine/:workstationForm', async function (
  context
) {
  // console.log('page form')
  // console.log(context)
  await appStarter.pageLoader()

  pageModuleForms.show(context.params)
})

page('/feedstock', async function (context) {
  // console.log('page feedstock')
  await appStarter.pageLoader()

  pageModuleFeed.show()
})

page('/inventory-feed', async function (context) {
  // console.log('page inventory')
  await appStarter.pageLoader()

  pageModuleInventoryFeed.show()
})

page('/form-records', async function (context) {
  // console.log('page form records')
  await appStarter.pageLoader()

  pageModuleFormRecords.show()
})

page('/patterns', async function (context) {
  await appStarter.pageLoader()

  pageModulePatterns.show()
})

page('/frass', async function (context) {
  await appStarter.pageLoader()

  pageModuleFrass.show()
})

page('/quality', async function (context) {
  await appStarter.pageLoader()

  pageModuleQuality.show()
})

page('*', function (context) {
  // console.log('other routes', context)

  // App Loader
  appStarter.pageLoader()
})

// Init the router
page()
