import * as inventoryHelpers from './helpers.js'
import * as chartsInventory from '../../helpers/charts/inventory.js'

export function showIngredientCharts () {
  const chartParams = inventoryHelpers.chartParams
  // console.log('showIngredientCharts', chartParams)
  const flowsPerIngredient = chartParams.flowsPerIngredient
  const timeframe = chartParams.timeframe

  const containerElement = document.getElementById('individualMaterials')

  const chartDatasets = [
    'receiving',
    'nursery',
    'breeding',
    'farming',
    'adjustments',
    'runningInventory'
  ]

  // Loop through all ingredients
  Object.keys(flowsPerIngredient).forEach(function (oneIngredient) {
    const _chartParams = {
      ingredientName: oneIngredient,
      timeframe,
      datasets: [],
      unit: 'Kg',
      dates: chartParams.dates,
      title: 'Flows and Inventory'
    }

    // The ingredient usage
    Object.keys(flowsPerIngredient[oneIngredient]).forEach(function (
      oneIngredientFlow
    ) {
      // flowsPerIngredient[oneIngredient][oneIngredientFlow].values

      if (!chartDatasets.includes(oneIngredientFlow)) return // Not to be in the chart

      _chartParams.datasets.push({
        flowType: oneIngredientFlow,
        values: flowsPerIngredient[oneIngredient][oneIngredientFlow].values
      })
    })
    const chartId = oneIngredient.replaceAll(' ', '')

    const chartElementId = 'c_' + chartId

    const chartElementDOM = document.getElementById(chartElementId)
    if (!chartElementDOM) {
      const chartElement = document.createElement('div')
      chartElement.setAttribute('id', chartElementId)
      chartElement.classList.add('chart')

      containerElement.appendChild(chartElement)
    }

    _chartParams.chartElementId = chartElementId
    chartsInventory.drawInventoryChart(_chartParams)

    // The ingredient days of availability
    // return
    makeIngredientDaysChart({
      oneIngredient,
      dates: chartParams.dates,
      timeframe,
      flowsPerIngredient,
      containerElement
    })
  })
}

function makeIngredientDaysChart (params) {
  const oneIngredient = params.oneIngredient
  const timeframe = params.timeframe
  const dates = params.dates
  const flowsPerIngredient = params.flowsPerIngredient
  const containerElement = params.containerElement

  const _chartParams = {
    ingredientName: oneIngredient,
    timeframe,
    datasets: [],
    unit: 'days',
    dates,
    title: 'Running Days'
  }

  const chartDatasets = ['daysOfInventoryEstimate']

  // The ingredient usage
  Object.keys(flowsPerIngredient[oneIngredient]).forEach(function (
    oneIngredientFlow
  ) {
    // flowsPerIngredient[oneIngredient][oneIngredientFlow].values

    if (!chartDatasets.includes(oneIngredientFlow)) return // Not to be in the chart

    _chartParams.datasets.push({
      flowType: oneIngredientFlow,
      values: flowsPerIngredient[oneIngredient][oneIngredientFlow].values
    })
  })
  const chartId = oneIngredient.replaceAll(' ', '')

  const chartElementId = 'doi_' + chartId

  const chartElementDOM = document.getElementById(chartElementId)
  if (!chartElementDOM) {
    const chartElement = document.createElement('div')
    chartElement.setAttribute('id', chartElementId)
    chartElement.classList.add('chart')

    containerElement.appendChild(chartElement)
  }

  _chartParams.chartElementId = chartElementId
  chartsInventory.drawInventoryChart(_chartParams)
}
