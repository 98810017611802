import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'

import * as exportFormRecords from '../csv-exports/form-submissions.js'

import * as recordsSettings from './settings.js'
import * as recordsLayoutFilters from './filters.js'
import * as recordsShow from './show.js'

import htmlIndex from './index.html'

export async function show () {
  // console.log('module show')

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  recordsLayoutFilters.manageLayout()

  await loadFormRecords()
  recordsShow.showFormRecords()
}

async function loadFormRecords () {
  const formRecords = await exportFormRecords.recordsData()
  // console.log('formRecords', formRecords)

  const formsByDay = analyseFormRecords(formRecords)
  recordsSettings.updateFormsByDay(formsByDay)
}

function analyseFormRecords (formRecords) {
  formRecords.forEach(function (oneFormRecord) {
    analyseOneForm(oneFormRecord)
  })
  // // console.log(formRecords)

  const formsByDay = d3.group(formRecords, r => r.recordDay)
  // console.log('formsByDay', formsByDay)
  return Array.from(formsByDay)
}

function analyseOneForm (oneFormRecord) {
  oneFormRecord.recordDay = oneFormRecord.usableTimestamp.substring(0, 10)
  oneFormRecord.recordHour = oneFormRecord.usableTimestamp.substring(11, 13)
}
