import * as configVar from '../../config.js'
import * as userModule from '../../helpers/auth/user.js'
import * as dataseriesModule from '../../helpers/dataseries/dataseries.js'
import * as ratioseriesModule from '../../helpers/dataseries/ratioseries.js'

import * as chartsTrends from '../../helpers/charts/trends.js'
import * as chartsMTD from '../../helpers/charts/mtd.js'
import * as chartsRatios from '../../helpers/charts/ratios.js'
import * as chartsPeriods from '../../helpers/charts/periods.js'
import * as chartsPrinting from '../../helpers/charts/printing.js'

import * as productionDashboardHelpers from './helpers.js'
import * as productionDashboardLayoutFilters from './layout-filters.js'

import htmlIndex from './index.html'

//
export function show () {
  // console.log("module show");

  if (!userModule.isUserLoggedIn()) return

  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  loadProductionData()

  productionDashboardLayoutFilters.manageLayout()
}

function loadProductionData () {
  // console.log("loadProductionData");
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'data', 'production-data'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log("urlApi", urlApi);
  fetch(urlApi, fetchOptionsApi)
    .then((response) => response.json())
    .then(function (receivedJson) {
      // console.log("api loaded", urlApi);
      const productionData = receivedJson.data

      generateDashboard(productionData)
    })
}

export function updateCouchDBview () {
  // console.log("refreshCouchDBview");
  // Calling backend
  const urlApi = [configVar.apiRoutes, 'data', 'production-data', 'update-couchdb-view'].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log("urlApi", urlApi);
  fetch(urlApi, fetchOptionsApi)
    .then((response) => response.json())
    .then(function (receivedJson) {
      // console.log("api loaded", urlApi);
      // console.log(receivedJson);
    })
}

function generateDashboard (productionData) {
  // console.log("generateDashboard", productionData);
  const dailyValuesObj = productionData.dailyValuesObj
  const timeframe = productionData.timeframe
  const dailyTargets = productionData.dailyTargets
  const highlights = productionData.highlights

  // Create the DB of metrics
  const metricsDB = {}

  fillDataseries({
    dailyValuesObj,
    timeframe,
    metricsDB
  })

  fillRatioseries({
    dailyValuesObj,
    timeframe,
    metricsDB
  })

  // console.log("metricsDB", metricsDB);

  drawCharts({
    metricsDB,
    timeframe,
    dailyTargets,
    highlights
  })

  chartsPrinting.registerChartResizingForPrinting()

  productionDashboardHelpers.allowFullScreen()
  productionDashboardHelpers.allowDownload()
  productionDashboardHelpers.listenToUpdateTrendCharts()
  productionDashboardHelpers.listenToStartDateSelector()
  productionDashboardHelpers.listenToEndDateSelector()
  productionDashboardHelpers.listenToTimeframeShortcut()

  return metricsDB
}

function fillDataseries (params) {
  const dailyValuesObj = params.dailyValuesObj
  const timeframe = params.timeframe
  const metricsDB = params.metricsDB

  // **** UPDATE WHEN CHANGING VIEW
  metricsDB.eggsCollection = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'eggsCollection',
    unit: 'g'
  })
  metricsDB.neonatesCollection = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'neonatesCollection',
    unit: 'g'
  })
  metricsDB.seedlingsAverageWeight = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'seedlingsAverageWeight',
    unitMultiplier: 1000,
    unit: 'mg'
  })
  metricsDB.seedlingsProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'seedlingsProduction',
    unit: 'g'
  })
  metricsDB.prepupaeAverageWeight = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'prepupaeAverageWeight',
    unitMultiplier: 1000,
    unit: 'mg'
  })
  metricsDB.prepupaeHarvestAverageWeight = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'prepupaeHarvestAverageWeight',
    unitMultiplier: 1000,
    unit: 'mg'
  })
  metricsDB.prepupaeProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'prepupaeProduction',
    unit: 'kg'
  })
  metricsDB.pupaeProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'pupaeProduction',
    unit: 'kg'
  })
  metricsDB.farmingFreshLarvaeProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingFreshLarvaeProduction',
    unit: 'kg'
  })
  metricsDB.farmingLarvaeHarvestAverageWeight = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingLarvaeHarvestAverageWeight',
    unitMultiplier: 1000,
    unit: 'mg'
  })
  metricsDB.manufacturingDriedLarvaeProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDriedLarvaeProduction',
    unit: 'kg'
  })
  metricsDB.breedingFrassProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'breedingFrassProduction',
    unit: 'kg'
  })
  metricsDB.farmingFrassProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingFrassProduction',
    unit: 'kg'
  })
  metricsDB.totalFrassProduction = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: ['breedingFrassProduction', 'farmingFrassProduction'],
    unit: 'kg'
  })
  metricsDB.seedlingsForBreeding = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'seedlingsForBreeding',
    unit: 'g'
  })
  metricsDB.seedlingsForFarming = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'seedlingsForFarming',
    unit: 'g'
  })
  metricsDB.seedlingsTotalUtilisation = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: ['seedlingsForBreeding', 'seedlingsForFarming'],
    unit: 'g'
  })
  metricsDB.processingDriedLarvaeInput = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'processingDriedLarvaeInput',
    unit: 'kg'
  })
  metricsDB.processingHermetOilOutput = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'processingHermetOilOutput',
    unit: 'kg'
  })
  metricsDB.processingHermetProteinOutput = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'processingHermetProteinOutput',
    unit: 'kg'
  })
  metricsDB.manufacturingDryingEnergyUsed = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDryingEnergyUsed',
    unit: 'kWh'
  })
  metricsDB.manufacturingDryingEnergyPerDriedLarvae = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDryingEnergyPerDriedLarvae',
    unit: 'kWh/Kg Dried Larvae'
  })
  metricsDB.manufacturingDryingEnergyAtStart = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDryingEnergyAtStart',
    unit: 'kWh at Day Start'
  })
  metricsDB.manufacturingDryingEnergyAtEnd = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDryingEnergyAtEnd',
    unit: 'kWh at Day End'
  })
  metricsDB.manufacturingDryingEnergyOutsideDrying = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'manufacturingDryingEnergyOutsideDrying',
    unit: 'kWh at Day End'
  })
  metricsDB.farmingRacksSetup = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingRacksSetup',
    unit: 'Racks'
  })
  metricsDB.breedingRacksSetup = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'breedingRacksSetup',
    unit: 'Racks'
  })
  metricsDB.farmingRacksHarvest = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingRacksHarvest',
    unit: 'Racks'
  })
  metricsDB.breedingRacksHarvest = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'breedingRacksHarvest',
    unit: 'Racks'
  })
  metricsDB.eggsCountPerGram = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'eggsCountPerGram',
    unit: ''
  })

  metricsDB.farmingHarvestAveragePerRack = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingHarvestAveragePerRack',
    unit: 'Kg of Larvae Harvested per Rack'
  })
  metricsDB.farmingRackHarvestLowWeight = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'farmingRackHarvestLowWeight',
    unit: 'Racks with <10Kg Larvae'
  })
  metricsDB.breedingPrepupaeHarvestAveragePerRack = dataseriesModule.generateDataSeries({
    timeframe: timeframe,
    dailyValuesObj: dailyValuesObj,
    metricName: 'breedingPrepupaeHarvestAveragePerRack',
    unit: 'Kg of Prepupae Harvested per Rack'
  })
}

function fillRatioseries (params) {
  // const dailyValuesObj = params.dailyValuesObj
  const timeframe = params.timeframe
  const metricsDB = params.metricsDB

  metricsDB.eggsToSeedlings = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['eggsCollection', 'seedlingsTotalUtilisation'],
    valueSeries: 'r7d1',
    divisorLagDays: 6, // R script: 1 day egg to neonate, 5 day neother to seedling
    rollingAggregation: {
      rollingDays: 28,
      rollingDivider: 28
    }
  })
  metricsDB.eggsToNeonates = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['eggsCollection', 'neonatesCollection'],
    valueSeries: 'dailyValues', // 'r7d1',
    divisorLagDays: 5,
    ratioUnit: '%',
    rollingAggregation: {
      rollingDays: 1,
      rollingDivider: 1
    }
  })
  metricsDB.neonatesToSeedlings = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['neonatesCollection', 'seedlingsTotalUtilisation'],
    valueSeries: 'dailyValues', // 'r7d1',
    divisorLagDays: 5,
    rollingAggregation: {
      rollingDays: 1,
      rollingDivider: 1
    }
  })

  metricsDB.seedlingsProducedToUtilised = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['seedlingsProduction', 'seedlingsTotalUtilisation'],
    valueSeries: 'r7d1',
    divisorLagDays: 0,
    ratioUnit: '%',
    rollingAggregation: {
      rollingDays: 7,
      rollingDivider: 7
    }
  })
  metricsDB.eggsToLarvae = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['eggsCollection', 'farmingFreshLarvaeProduction'],
    unitMultipliers: [0.001, 1],
    valueSeries: 'r7d1',
    divisorLagDays: 0 // Farming rack process to harvest on Day 8
  })

  metricsDB.seedlingsToLarvae = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['seedlingsProduction', 'farmingFreshLarvaeProduction'],
    unitMultipliers: [0.001, 1],
    valueSeries: 'r7d1',
    divisorLagDays: 8 // Farming rack process to harvest on Day 8
  })
  metricsDB.prepupaeToPupae = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['prepupaeProduction', 'pupaeProduction'],
    valueSeries: 'r7d1',
    divisorLagDays: 4 // What should this be?
  })
  metricsDB.prepupaeToEggs = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['prepupaeProduction', 'eggsCollection'],
    valueSeries: 'r7d1',
    divisorLagDays: 10, // It takes ~10 days for prepupae to become pupae, then fly, then egg
    rollingAggregation: {
      rollingDays: 28,
      rollingDivider: 28
    }
  })
  metricsDB.larvaeToDried = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingFreshLarvaeProduction', 'manufacturingDriedLarvaeProduction'],
    valueSeries: 'r7d1',
    divisorLagDays: 1,
    ratioUnit: '%'
  })
  metricsDB.larvaeToDried7d = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingFreshLarvaeProduction', 'manufacturingDriedLarvaeProduction'],
    valueSeries: 'r7d7',
    divisorLagDays: 0,
    ratioUnit: '%'
  })
  metricsDB.larvaeToDried28d = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingFreshLarvaeProduction', 'manufacturingDriedLarvaeProduction'],
    valueSeries: 'r28d28',
    divisorLagDays: 0,
    ratioUnit: '%'
  })
  metricsDB.larvaeToDried31d = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingFreshLarvaeProduction', 'manufacturingDriedLarvaeProduction'],
    valueSeries: 'r31d31',
    divisorLagDays: 0,
    ratioUnit: '%'
  })
  metricsDB.larvaeToFrass = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingFreshLarvaeProduction', 'farmingFrassProduction'],
    valueSeries: 'r7d1',
    divisorLagDays: 0,
    rollingAggregation: {
      rollingDays: 28,
      rollingDivider: 28
    }
  })
  metricsDB.seedlingsForBreedingRatio = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['seedlingsTotalUtilisation', 'seedlingsForBreeding'],
    valueSeries: 'dailyValues',
    divisorLagDays: 0,
    ratioUnit: '%',
    rollingAggregation: {
      rollingDays: 7,
      rollingDivider: 7
    }
  })
  metricsDB.driedLarvaeToProtein = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['processingDriedLarvaeInput', 'processingHermetProteinOutput'],
    valueSeries: 'dailyValues',
    divisorLagDays: 0,
    ratioUnit: '%'
  })
  metricsDB.driedLarvaeToOil = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['processingDriedLarvaeInput', 'processingHermetOilOutput'],
    valueSeries: 'dailyValues',
    divisorLagDays: 0,
    ratioUnit: '%'
  })
  metricsDB.farmingFreshLarvaeCountEstimateFromEggsCollection = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['eggsCountPerGram', 'eggsCollection'],
    valueSeries: 'dailyValues',
    divisorLagDays: 0,
    ratioUnit: ''
  })
  metricsDB.farmingFreshLarvaeHarvestCountEstimateFromAverageWeight = ratioseriesModule.generateRatioSeries({
    timeframe: timeframe,
    metricsDB: metricsDB,
    metricNames: ['farmingLarvaeHarvestAverageWeight', 'farmingFreshLarvaeProduction'],
    valueSeries: 'dailyValues',
    unitMultipliers: [0.001, 1000],
    divisorLagDays: 0,
    ratioUnit: ''
  })
  metricsDB.farmingFreshLarvaeCountEstimateFromAvgWeightVsCountFromEggsCollected =
    ratioseriesModule.generateRatioSeries({
      timeframe: timeframe,
      metricsDB: metricsDB,
      metricNames: [
        'farmingFreshLarvaeCountEstimateFromEggsCollection',
        'farmingFreshLarvaeHarvestCountEstimateFromAverageWeight'
      ],
      valueSeries: 'ratioValues',
      divisorLagDays: 0,
      ratioUnit: '%',
      rollingAggregation: {
        rollingDays: 7,
        rollingDivider: 7
      }
    })
}

function drawCharts (params) {
  // console.log("drawCharts", params);

  // **** UPDATE WHEN CHANGING VIEW
  params.metricNames = [
    'eggsCollection',
    'eggsCountPerGram',
    'neonatesCollection',
    'seedlingsAverageWeight',
    'seedlingsProduction',
    'prepupaeAverageWeight',
    'prepupaeHarvestAverageWeight',
    'prepupaeProduction',
    'pupaeProduction',
    'farmingFreshLarvaeProduction',
    'farmingLarvaeHarvestAverageWeight',
    'manufacturingDriedLarvaeProduction',
    'totalFrassProduction',
    'seedlingsTotalUtilisation',
    'seedlingsForBreeding',
    'seedlingsForFarming',
    'processingDriedLarvaeInput',
    'processingHermetProteinOutput',
    'processingHermetOilOutput',
    'manufacturingDryingEnergyUsed',
    'manufacturingDryingEnergyPerDriedLarvae',
    'manufacturingDryingEnergyAtStart',
    'manufacturingDryingEnergyAtEnd',
    'manufacturingDryingEnergyOutsideDrying',
    'farmingRacksSetup',
    'breedingRacksSetup',
    'farmingRacksHarvest',
    'breedingRacksHarvest',
    'farmingHarvestAveragePerRack',
    'farmingRackHarvestLowWeight',
    'breedingPrepupaeHarvestAveragePerRack'
  ]

  params.mtdNames = [
    'eggsCollection',
    'seedlingsProduction',
    'seedlingsTotalUtilisation',
    'prepupaeProduction',
    'farmingFreshLarvaeProduction',
    'manufacturingDriedLarvaeProduction',
    'totalFrassProduction',
    'manufacturingDryingEnergyUsed',
    'farmingRacksSetup',
    'breedingRacksSetup',
    'farmingRacksHarvest',
    'breedingRacksHarvest'
  ]

  params.ratioNames = [
    'eggsToSeedlings',
    'eggsToNeonates',
    'eggsToLarvae',
    'neonatesToSeedlings',
    'seedlingsToLarvae',
    'seedlingsProducedToUtilised',
    'prepupaeToPupae',
    'prepupaeToEggs',
    'larvaeToDried',
    'larvaeToDried7d',
    'larvaeToDried28d',
    'larvaeToDried31d',
    'larvaeToFrass',
    'seedlingsForBreedingRatio',
    'driedLarvaeToProtein',
    'driedLarvaeToOil',
    'farmingFreshLarvaeCountEstimateFromEggsCollection',
    'farmingFreshLarvaeHarvestCountEstimateFromAverageWeight',
    'farmingFreshLarvaeCountEstimateFromAvgWeightVsCountFromEggsCollected'
  ]

  params.periodNames = [
    'eggsCollection',
    'seedlingsProduction',
    'seedlingsTotalUtilisation',
    'farmingFreshLarvaeProduction',
    'manufacturingDriedLarvaeProduction',
    'totalFrassProduction',
    'processingHermetProteinOutput',
    'processingHermetOilOutput',
    'manufacturingDryingEnergyUsed',
    'manufacturingDryingEnergyOutsideDrying',
    'farmingRacksSetup',
    'breedingRacksSetup',
    'farmingRacksHarvest',
    'breedingRacksHarvest',
    'prepupaeProduction',
    'pupaeProduction'
  ]

  const chartParams = productionDashboardHelpers.setChartParams(params)

  chartsTrends.drawTrendCharts({
    chartParams
  })

  chartsMTD.drawMTDCharts({
    chartParams
  })

  chartsRatios.drawRatioCharts({
    chartParams
  })

  chartsPeriods.drawPeriodCharts({
    chartParams
  })
}
