export const usersList = {
  Iszwan: ['breeding', 'supervisor'],
  Suhaila: ['breeding'],
  // Norliza: ['breeding'],
  Liza: ['breeding'],
  Syafiq: ['breeding'],
  Akash: ['breeding'],

  Rashvin: ['farming', 'supervisor'],
  Farhan: ['farming'],
  Loo: ['farming'],
  Ripon: ['farming'],
  Raihan: ['farming'],
  Arif: ['farming'],
  Minul: ['farming'],

  Zaid: ['manufacturing', 'supervisor'],
  Rizal: ['manufacturing'],
  Fauzi: ['manufacturing'],
  Firdaus: ['manufacturing'],

  Nazafi: ['otherProd', 'maintenance', 'supervisor'],
  Denis: ['otherProd', 'maintenance'],
  Sureantheran: ['otherProd', 'maintenance'],
  Hamizan: ['otherProd', 'sanitation'],
  Azrizan: ['otherProd', 'sanitation'],
  Aisha: ['otherProd', 'qa-qc'],
  Atif: ['otherProd', 'engineering'],
  // ain: ['otherProd','engineering'],
  'Ain Sufi': ['otherProd', 'engineering']

  // Salamahafifi: ['corporate'],
  // Leo: 'corporate'
}

export const defaultSort = [
  'supervisor',
  'breeding',
  'farming',
  'manufacturing',
  'maintenance',
  'sanitation',
  'qa-qc'
]

export let formsByDay = {}

export function updateFormsByDay (forms) {
  formsByDay = forms
}

export const layoutFilters = new Set()
layoutFilters.add('farming') // Default
