// This should be a variable so it can be different for environment
export const host = 'http://localhost:3001'

// Routing
export const appRoutes = '/app'
export const apiRoutes = '/api'
export const authRoutes = '/auth'

// General variables
export const contentElementId = 'content'
export const baseUrlToiOS = 'https://farm.johor.insects.network/ui/#/forms/edit'
export const creationUrlIOS =
  'https://farm.johor.insects.network/ui/#/forms/type'
