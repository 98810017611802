import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

export async function loadAndexportCsv () {
  // console.log('loadAndexportCsv')
  const recordValues = await loadApiData()
  const recordArray = rackSetupHarvest.generateExportArray(recordValues)
  const csvFormatted = d3.csvFormat(recordArray)
  helpersCsvExports.downloadCsv(csvFormatted)
}

export async function loadData () {
  const recordValues = await loadApiData()
  return recordValues
}

//
async function loadApiData () {
  const urlApi = [
    configVar.apiRoutes,
    'csv_exports',
    'rack-setup-harvest'
  ].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  console.time('loadAndexportCsv')
  return fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      console.timeEnd('loadAndexportCsv')
      // console.log('api loaded', urlApi)
      // console.log(data)
      const recordValues = data.data

      return recordValues
    })
}

const rackSetupHarvest = {}

rackSetupHarvest.generateExportArray = function (records) {
  const exportArray = []

  const numberRows = 50000000
  records.forEach(function (oneRecord) {
    const rowData = rackSetupHarvest.exportRow(oneRecord)

    if (exportArray.length > numberRows) return
    exportArray.push(rowData)
  })
  return exportArray
}

// Decide the properties to enforce order in csv
rackSetupHarvest.exportRow = function (oneRecord) {
  const oneRow = {}

  const recordValues = oneRecord

  oneRow.rackId = recordValues.rackId
  oneRow.s_growthChamber = recordValues.s_growthChamber
  oneRow.s_timestamp = recordValues.s_timestamp
  oneRow.s_by = recordValues.s_by
  oneRow.s_timestampDelta = recordValues.s_timestampDelta
  oneRow.s_type = recordValues.s_type
  oneRow.s_trays = recordValues.s_trays
  oneRow.s_feedstockPerTray = recordValues.s_feedstockPerTray
  oneRow.s_feedstock = recordValues.s_feedstock
  oneRow.s_5DOL = recordValues.s_5DOL
  oneRow.s_5DOLarvaeWeight = recordValues.s_5DOLarvaeWeight

  oneRow.h_growthChamber = recordValues.h_growthChamber
  oneRow.h_timestamp = recordValues.h_timestamp
  oneRow.h_by = recordValues.h_by
  oneRow.h_timestampDelta = recordValues.h_timestampDelta
  oneRow.h_type = recordValues.h_type
  oneRow.h_trays = recordValues.h_trays
  oneRow.h_frass = recordValues.h_frass
  oneRow.h_larvae = recordValues.h_larvae
  oneRow.h_larvaeAverageWeight = recordValues.h_larvaeAverageWeight

  oneRow.rackAgeHours = recordValues.rackAgeHours
  oneRow.rackAgeDays = recordValues.rackAgeDays
  oneRow.rackBCE = recordValues.rackBCE

  return oneRow
}
