import { Chart, registerables } from 'chart.js'

import * as helpersTimeseries from '../timeseries/timeseries.js'
import { chartInstances } from './instances.js'
Chart.register(...registerables)

export function drawRatioCharts (params = {}) {
  // // console.log('drawRatioCharts', params)
  const chartParams = params.chartParams
  const ratioNames = chartParams.ratioNames

  ratioNames.forEach(function (oneMetricName) {
    drawRatioChart({
      metricName: oneMetricName,
      dailyTargets: chartParams.dailyTargets,
      timeframe: chartParams.timeframe,
      metricsDB: chartParams.metricsDB,
      dates: chartParams.dates
    })
  })
}

function drawRatioChart (params = {}) {
  // // console.log('drawRatioChart', params)
  const metricName = params.metricName
  const metricsDB = params.metricsDB
  const metricDB = metricsDB[metricName]
  const dailyTargets = params.dailyTargets

  if (!metricDB) {
    console.warn('Not available', params)
  }

  const isShowTarget = true

  const config = {
    type: 'line',
    options: {
      maintainAspectRatio: false, // Fill the container size
      plugins: {
        title: {
          display: true,
          text: 'Ratio: ' + metricName
        },
        legend: {
          position: 'bottom'
          // reverse: true
        }
      },
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          }
        },
        y: {
          display: true,
          position: 'left',
          ticks: {
            color: '#4285f4'
          },
          min: 0
        },
        y1: {
          display: true,
          position: 'right',
          ticks: {
            color: 'rgba(180,148,250)'
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
            // display: false
          },
          min: 0
        }
      },
      // Interaction should allow to show multiple values on mouseover
      interaction: {
        mode: 'index',
        intersect: false
      }
    }
  }

  // Show the ratio labels in % if needed
  if (metricDB.ratioUnit === '%') {
    config.options.scales.y.ticks.callback = function (value) {
      return value + '%'
    }

    config.options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            if (context.datasetIndex === 0) {
              label += context.parsed.y + '%'
            } else {
              label += context.formattedValue
            }
          }
          return label
        }
      }
    }
  }

  const chartContainerId = 'r_' + metricName
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  // Evaluate the timeframe matching the params.dates
  let dateTimeframe = params.timeframe

  const firstIndex = helpersTimeseries.getFirstIndex(params)
  const lastIndex = helpersTimeseries.getLastIndex(params)

  dateTimeframe = dateTimeframe.slice(firstIndex, lastIndex)

  // Set the Chart to have such xAxis labels
  const chartData = {
    labels: dateTimeframe,
    datasets: []
  }

  // Show the ratio line
  const ratioLineLabel = metricDB.rollingAggregation
    ? [metricName, ' r', metricDB.rollingAggregation.rollingDays].join('')
    : metricName
  const datasetRatio = {
    label: ratioLineLabel,
    type: 'line',
    data: metricDB.ratioValues.slice(firstIndex, lastIndex),
    backgroundColor: '#4285f4',
    borderColor: '#4285f4',
    borderWidth: 4,
    pointRadius: 2,
    pointBorderWidth: 0
    // spanGaps: true
  }
  chartData.datasets.push(datasetRatio)

  // Show the Dividend values
  const datasetDividend = {
    label: metricDB.metricNames[1],
    type: 'line',
    data: metricDB.dividends.slice(firstIndex, lastIndex),
    backgroundColor: 'rgba(180,148,250,0.4)',
    borderColor: 'rgba(180,148,250,0.4)',
    borderWidth: 2,
    pointRadius: 1,
    pointBorderWidth: 0,
    // radius: 0,
    yAxisID: 'y1'
  }
  chartData.datasets.push(datasetDividend)

  // Show the Divisor values
  const datasetDivisor = {
    label: metricDB.metricNames[0],
    type: 'line',
    data: metricDB.divisors.slice(firstIndex, lastIndex),
    backgroundColor: 'rgba(180,148,250,0.3)',
    borderColor: 'rgba(180,148,250,0.3)',
    borderWidth: 2,
    pointRadius: 1,
    pointBorderWidth: 0,
    yAxisID: 'y1'
  }
  chartData.datasets.push(datasetDivisor)

  // Show the delayed Divisors if a delay was applied
  if (metricDB.divisorLagDays > 0) {
    const datasetDivisorDelayed = {
      label: [
        metricDB.metricNames[0],
        ' + ',
        metricDB.divisorLagDays,
        ' Days'
      ].join(''),
      type: 'line',
      data: metricDB.divisorsLagged.slice(firstIndex, lastIndex),
      backgroundColor: 'rgba(180,148,250,0.3)',
      borderColor: 'rgba(180,148,250,0.3)',
      borderWidth: 2,
      radius: 0,
      yAxisID: 'y1'
    }
    chartData.datasets.push(datasetDivisorDelayed)
  }

  // Show target
  // Show the daily target
  if (isShowTarget) {
    if (dailyTargets[metricName]) {
      const targetValues = Array(dateTimeframe.length).fill(
        dailyTargets[metricName]
      )
      chartData.datasets.push({
        label: 'Daily Target',
        data: targetValues,
        type: 'line',
        radius: 0,
        borderColor: 'rgba(247,177,24,0.5)',
        backgroundColor: 'rgba(247,177,24,0.5)',
        order: 1
      })
    }
  }

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}
