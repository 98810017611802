import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'
import * as helpersDataParsing from '../../helpers/data/parsing.js'
// import * as settings from './pattern-settings.js'

export function createPatternData (params) {
  // console.log('createPatternData', params)

  const finderParams = Object.assign(
    {
      propertyName: 'frassPile'
    },
    params
  )

  const patternData = {}

  patternData.lines = helpersDataParsing.findPropertyValues(finderParams)
  patternData.firstDay = findFirstRecord(params)

  patternData.timeframe = helpersTimeseries.generateTimeframe({
    minDay: patternData.firstDay,
    sort: -1
  })

  patternData.indexedRecords = indexPatternData(params)

  showSomeRecords(params)
  return patternData
}

/*
  Identify the first record
  Add date YYYY-MM-DD properties
*/
function findFirstRecord (params) {
  const records = params.records

  let firstSetup = null

  records.forEach(function (oneRecord) {
    // Store YYYY-MM-DD dates
    oneRecord.activityDay = oneRecord.timestamp?.substring(0, 10)

    if (!oneRecord.activityDay) {
      // frass-turning 'timestamp' is in sub object 'turning', let's use the submission timestamp
      oneRecord.activityDay = oneRecord.submission_timestamp?.substring(0, 10)
    }
    if (!firstSetup || oneRecord.activityDay < firstSetup) {
      firstSetup = oneRecord.activityDay
    }
  })

  return firstSetup
}

/*
  [day][line][subLine]
*/
function indexPatternData (params) {
  const records = params.records

  const indexedRecordPattern = {}

  records.forEach(function (oneRecord) {
    const activityDay = oneRecord.activityDay
    indexedRecordPattern[activityDay] = indexedRecordPattern[activityDay] || {}

    const line = oneRecord.frassPile
    indexedRecordPattern[activityDay][line] =
      indexedRecordPattern[activityDay][line] || {}

    const mappedForm = formAllocation[oneRecord.workstationForm]
    indexedRecordPattern[activityDay][line][mappedForm] =
      indexedRecordPattern[activityDay][line][mappedForm] || []
    indexedRecordPattern[activityDay][line][mappedForm].push(oneRecord)
  })
  return indexedRecordPattern
}

function showSomeRecords (params) {
  // const records = params.records
  // records.forEach(function (oneRecord) {
  //   if (oneRecord.workstationForm === 'frass-watering') {
  //     // console.log('!!! watering', oneRecord)
  //   }
  //   if (oneRecord.workstationForm === 'frass-turning') {
  //     // console.log('!!! turning', oneRecord)
  //   }
  // })
}

const formAllocation = {
  'frass-check': 'checks',
  'frass-transfer': 'flows',
  'frass-packing': 'flows',
  'frass-watering': 'activity',
  'frass-turning': 'activity'
}
