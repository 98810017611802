import { Chart, registerables } from 'chart.js'
import autocolors from 'chartjs-plugin-autocolors'

import * as helpersTimeseries from '../timeseries/timeseries.js'
import { chartInstances } from './instances.js'

// import * as configVar from '../../config.js'
Chart.register(...registerables)
Chart.register(autocolors)

/*
  @params
    .chartContainerId
    .unit
    .timeframe
    .datasets: [
      {
        values: []
        name
      }
    ]
*/
export function drawBarBreakdownChart (params = {}) {
  // console.log('drawBarBreakdownChart', params)

  const config = {
    type: 'bar',
    options: {
      maintainAspectRatio: false, // Fill the container size
      scales: {
        x: {
          grid: {
            display: false // Don't show vertical grid lines
          },
          stacked: true
        },
        y: {
          title: {
            display: true,
            text: params.unit
          },
          stacked: true
        }
      },
      plugins: {
        title: {
          display: true,
          text: params.chartContainerId
        },
        legend: {
          position: 'right'
        },
        autocolors: {
          enabled: true,
          mode: 'dataset'
        }
        // colorschemes: {
        //   scheme: 'brewer.Paired12'
        // }
      },
      interaction: {
        mode: 'index',
        intersect: false
      }
      // appContext: {
      //   metricsDB: params.metricsDB,
      //   timeframe: params.timeframe,
      //   chartParams: params
      // }
    }
  }

  const chartContainerId = 'b_' + params.chartContainerId
  if (!chartInstances[chartContainerId]) {
    const divElement = document.getElementById(chartContainerId)
    if (!divElement) return
    const canvasElement = document.createElement('canvas')
    divElement.appendChild(canvasElement)

    chartInstances[chartContainerId] = new Chart(canvasElement, config)
  }
  const chart = chartInstances[chartContainerId]

  let dateTimeframe = params.timeframe
  const firstIndex = helpersTimeseries.getFirstIndex(params)
  const lastIndex = helpersTimeseries.getLastIndex(params)

  dateTimeframe = dateTimeframe.slice(firstIndex, lastIndex)

  // Set the Chart to have such xAxis labels
  const chartData = {
    labels: dateTimeframe,
    datasets: []
  }

  // Chart datasets
  params.datasets.forEach(function (oneDataset, index) {
    chartData.datasets.push({
      label: oneDataset.name,
      data: oneDataset.values.slice(firstIndex, lastIndex),
      type: 'bar'
      // backgroundColor: chartsHelpers.COLORS[index]
      // backgroundColor: '#4285f4',
      // borderColor: '#4285f4',
      // pointRadius: 1,
      // order: 0 // 0 to draw first, and be at the top of the chart; other dataset see to have an order too
    })
  })

  chart.data = chartData

  chartInstances[chartContainerId].update()
  return chart
}
