import accounting from 'accounting'

import * as settings from './pattern-settings.js'

export function showPattern (params) {
  // console.log('showPattern', params)

  // lines = helpersDataParsing.findPropertyValues(finderParams)
  // patternData.timeframe

  // Get the container
  const containerElement = document.getElementById(settings.containerElementId)
  // console.log('containerElement', containerElement)
  if (!containerElement) return

  // Create the cssGrid
  const gridElement = document.createElement('div')
  gridElement.classList.add('cssGrid')
  containerElement.appendChild(gridElement)

  const generatorParams = Object.assign(
    {
      gridElement: gridElement
    },
    params
  )

  showHeader(generatorParams)
  showTimelineContent(generatorParams)
  // console.log('done')
}

//
function showHeader (params) {
  const lines = params.patternData.lines
  const gridElement = params.gridElement

  const patternSubLines = settings.patternSubLines

  // First row: the name of the lines
  let gridRow = 1
  let columnIndex = 1
  const headerCellLeft = document.createElement('div')
  headerCellLeft.style['grid-row-start'] = gridRow
  headerCellLeft.style['grid-row-end'] = gridRow + 1
  headerCellLeft.style['grid-column'] = columnIndex
  headerCellLeft.classList.add('headerCell')
  gridElement.appendChild(headerCellLeft)

  lines.forEach(function (oneLine) {
    // console.log(oneLine)
    columnIndex++
    const lineCell = document.createElement('div')
    lineCell.style['grid-row'] = gridRow
    lineCell.style['grid-column-start'] = columnIndex
    lineCell.style['grid-column-end'] = columnIndex + patternSubLines.length
    lineCell.classList.add('headerCell')
    lineCell.innerText = 'Pile ' + oneLine?.toUpperCase()
    gridElement.appendChild(lineCell)

    columnIndex = columnIndex + patternSubLines.length - 1
  })

  // Second Row: details
  columnIndex = 1
  gridRow = 2
  lines.forEach(function (oneLine) {
    patternSubLines.forEach(function (oneSubLine) {
      columnIndex++
      const lineCell = document.createElement('div')
      lineCell.style['grid-row'] = gridRow
      lineCell.style['grid-column'] = columnIndex
      lineCell.classList.add('headerCell')
      lineCell.innerText = oneSubLine.title
      gridElement.appendChild(lineCell)
    })
  })
}

//
//
function showTimelineContent (params) {
  const timeframe = params.patternData.timeframe

  timeframe.forEach(function (oneDay, timeframeIndex) {
    showOneDayRow({
      timeframeIndex,
      day: oneDay,
      lines: params.patternData.lines,
      gridElement: params.gridElement,
      indexedRecords: params.patternData.indexedRecords
    })
  })
}

//
function showOneDayRow (params) {
  // // console.log('showOneDayRow', params)
  const day = params.day
  const lines = params.lines
  const gridElement = params.gridElement
  const timeframeIndex = params.timeframeIndex
  const indexedRecords = params.indexedRecords

  const patternSubLines = settings.patternSubLines

  showTimeframeDayCell({
    day,
    gridElement,
    timeframeIndex
  })

  // Starts column at 2 due to the left column
  let columnIndex = 2
  lines.forEach(function (oneLine) {
    patternSubLines.forEach(function (oneSubLine) {
      const lineCell = document.createElement('div')
      lineCell.style['grid-row'] = timeframeIndex + 3
      lineCell.style['grid-column'] = columnIndex
      lineCell.classList.add('patternCell')
      lineCell.classList.add('clickable')
      lineCell.classList.add('bordered')
      lineCell.setAttribute('day', day)
      lineCell.setAttribute('line', oneLine)
      lineCell.setAttribute('subline', oneSubLine.title)

      const cellContent = generateCellContent({
        day,
        oneLine,
        oneSubLine,
        indexedRecords
      })

      if (typeof cellContent === 'object') {
        lineCell.append(cellContent)
      } else {
        lineCell.innerText = cellContent
      }
      gridElement.appendChild(lineCell)
      columnIndex++
    })
  })
}

function showTimeframeDayCell (params) {
  const day = params.day
  const gridElement = params.gridElement
  const timeframeIndex = params.timeframeIndex

  const lineCell = document.createElement('div')
  lineCell.style['grid-row'] = timeframeIndex + 3
  lineCell.style['grid-column'] = 1
  lineCell.innerText = day
  gridElement.appendChild(lineCell)
}

function getCellRecords (params) {
  const day = params.day
  const oneLine = params.oneLine
  const oneSubLine = params.oneSubLine
  const indexedRecords = params.indexedRecords

  return indexedRecords[day]?.[oneLine]?.[oneSubLine.title]
}

function generateCellContent (params) {
  const oneSubLine = params.oneSubLine

  if (oneSubLine.title === 'checks') {
    return showCellChecks(params)
  } else if (oneSubLine.title === 'flows') {
    return showCellFlows(params)
  } else if (oneSubLine.title === 'activity') {
    return showCellActivity(params)
  }
}

function showCellActivity (params) {
  const cellRecords = getCellRecords(params)
  if (!cellRecords) return ''

  const cellTextArray = []
  cellRecords.forEach(function (oneCellRecord) {
    if (oneCellRecord.workstationForm === 'frass-turning') {
      cellTextArray.push('Turning') // by ' + oneCellRecord.user_name)
    }
    if (oneCellRecord.workstationForm === 'frass-watering') {
      cellTextArray.push('Water')
    }
  })

  return cellTextArray.join(', ')
}

const temperatureSymbols = {
  checkTemperature: '°C',
  checkTemperatureTop: '°Ctop',
  checkTemperatureBottom: '°Cbot'
}

function showTemperature (params) {
  const temperatureParam = params.temperatureParam
  const oneCellRecord = params.oneCellRecord
  const temperatureValue = oneCellRecord[temperatureParam]
  const temp = accounting.formatMoney(temperatureValue, {
    symbol: temperatureSymbols[temperatureParam],
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s'
  })
  return temp
}

function showCellChecks (params) {
  const cellRecords = getCellRecords(params)
  if (!cellRecords) return ''

  const temperatureProperties = [
    'checkTemperature',
    'checkTemperatureTop',
    'checkTemperatureBottom'
  ]

  const cellTextArray = []
  cellRecords.forEach(function (oneCellRecord) {
    if (oneCellRecord.checkMoisture) {
      const temp = accounting.formatMoney(oneCellRecord.checkMoisture, {
        symbol: '%',
        precision: 0,
        thousand: ',',
        decimal: '.',
        format: '%v%s'
      })
      cellTextArray.push(temp)
    }

    temperatureProperties.forEach(function (oneTemperatureProperty) {
      if (oneCellRecord[oneTemperatureProperty]) {
        const temp = showTemperature({
          temperatureParam: oneTemperatureProperty,
          oneCellRecord
        })
        cellTextArray.push(temp)
      }
    })
  })

  return cellTextArray.join(' ')
}

function showCellFlows (params) {
  const cellRecords = getCellRecords(params)
  if (!cellRecords) return ''

  const cellElement = document.createElement('div')

  // const cellTextArray = []
  cellRecords.forEach(function (oneCellRecord) {
    if (oneCellRecord.workstationForm === 'frass-transfer') {
      const fromDate = new Date(oneCellRecord.frassProductionDateFrom)
        .toString()
        .substring(4, 10)
      const toDate = new Date(oneCellRecord.frassProductionDateTo)
        .toString()
        .substring(4, 10)

      const cellText = 'T:' + fromDate + ' to ' + toDate
      // cellTextArray.push(cellText)

      const cellTransfer = document.createElement('div')
      cellTransfer.innerText = cellText
      cellElement.appendChild(cellTransfer)
    }

    if (oneCellRecord.workstationForm === 'frass-packing') {
      const volume = accounting.formatMoney(
        oneCellRecord.packTotalCalculated / 1000,
        {
          symbol: 'MT',
          precision: 1,
          thousand: ',',
          decimal: '.',
          format: '%v%s'
        }
      )
      let packing =
        volume +
        ' (' +
        oneCellRecord.packQuantity +
        'x' +
        +oneCellRecord.packWeightPerPack +
        'Kg)'
      if (
        +oneCellRecord.packTotalCalculated !== +oneCellRecord.packTotalQuantity
      ) {
        packing =
          packing + '!! vs.' + oneCellRecord.packTotalQuantity + ' typed'
      }
      // cellTextArray.push(packing)

      const cellPacking = document.createElement('div')
      cellPacking.innerText = packing
      cellElement.appendChild(cellPacking)
    }
  })
  return cellElement
  // return cellTextArray.join(', ')
}
