export const containerElementId = 'frassActivityPattern'

export const patternSubLines = [
  {
    title: 'activity'
  },
  {
    title: 'checks'
  },
  {
    title: 'flows'
  }
]
