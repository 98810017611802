import * as helpersTimeseries from '../../helpers/timeseries/timeseries.js'

export const chartParams = {}

export function setChartParams (params = {}) {
  // console.log('setChartParams', params)

  if (params.flowsPerIngredient) {
    chartParams.flowsPerIngredient = params.flowsPerIngredient
  }

  if (params.timeframe) {
    chartParams.timeframe = params.timeframe
  }

  chartParams.dates = chartParams.dates || {}
  if (params.minDate) {
    chartParams.dates.min = params.minDate
  } else if (!chartParams?.dates?.min) {
    const daysInPast = 45
    chartParams.dates.min = helpersTimeseries.getDayWithOffset(-daysInPast)
  }

  if (params.maxDate) {
    chartParams.dates.max = params.maxDate
  } else if (!chartParams?.dates?.max) {
    // Default to today
    chartParams.dates.max = new Date().toJSON().substring(0, 10)
  }

  return chartParams
}
