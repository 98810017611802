import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

// Handle printing resizing
export function registerChartResizingForPrinting () {
  // console.log('registerChartResizingForPrinting')
  function beforePrintHandler () {
    // console.log('print')
    for (const id in Chart.instances) {
      // console.log(Chart.instances[id])
      Chart.instances[id].resize()
    }
  }
  window.addEventListener('beforeprint', beforePrintHandler)
}
