import * as d3 from 'd3'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

export async function loadAndexportCsv () {
  // console.log('loadAndexportCsv')
  const recordValues = await loadData()

  const exportArray = materialInventory.generateExportArray(recordValues)
  const csvFormatted = d3.csvFormat(exportArray)
  helpersCsvExports.downloadCsv(csvFormatted)
}

export async function materialReceivingData () {
  const recordValues = await loadData()
  const exportArray = materialInventory.generateExportArray(recordValues)

  return exportArray
}

//
//
async function loadData () {
  // Calling backend
  const urlApi = [
    configVar.apiRoutes,
    'csv_exports',
    'material-receiving'
  ].join('/')

  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  return fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      // console.log('api loaded', urlApi)
      // console.log(data)
      // const recordValues = helpersCsvExports.couchRecordsToArrayValues(data)
      const recordValues = data.data

      return recordValues
    })
}

//
const materialInventory = {}

materialInventory.generateExportArray = function (records) {
  const exportArray = []

  records.forEach(function (oneRecord) {
    const rowData = materialInventory.exportRow(oneRecord)
    exportArray.push(rowData)
  })
  return exportArray
}

materialInventory.exportRow = function (oneRecord) {
  const oneRow = {}

  const recordValues = oneRecord

  // Basics
  oneRow.type = recordValues.type
  oneRow.productionLine = recordValues.productionLine
  oneRow.workstationForm = recordValues.workstationForm
  oneRow.user_name = recordValues.user_name

  // Timestamps
  oneRow.usableTimestamp = recordValues.usableTimestamp
  oneRow.timestamp = recordValues.timestamp
  oneRow.submission_timestamp = recordValues.submission_timestamp
  oneRow.timestampsDelta = recordValues.timestampsDelta

  // Specifics
  oneRow.material = recordValues.form?.material
  oneRow['pack-size'] = +recordValues.form?.['pack-size']
  oneRow.quantity = +recordValues.form?.quantity
  oneRow.totalQuantity = +recordValues.form?.['total-quantity']
  oneRow.calculatedQuantity = oneRow['pack-size'] * oneRow.quantity
  oneRow.quantityDelta = oneRow.calculatedQuantity - oneRow.totalQuantity

  oneRow.supplier = recordValues.form?.supplier

  oneRow.material = recordValues.form?.material
  return oneRow
}
