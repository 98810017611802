import * as configVar from '../../config.js'

import * as settings from './pattern-settings.js'

export function cellListener (params) {
  const patternData = params.patternData

  const patternContainerElement = document.getElementById(
    settings.containerElementId
  )
  if (!patternContainerElement) return

  patternContainerElement.addEventListener('click', function (clickEvent) {
    const patternCellElement = clickEvent.target.closest('.patternCell')
    if (!patternCellElement) return

    const cellDay = patternCellElement.getAttribute('day')
    const cellLine = patternCellElement.getAttribute('line')
    const cellSubline = patternCellElement.getAttribute('subline')
    // console.log(cellDay, cellLine)

    const records = patternData.indexedRecords
    const cellRecords = records[cellDay]?.[cellLine]?.[cellSubline]
    // console.log(cellRecords)
    showCellDetails({
      cellRecords
    })
  })
}

function showCellDetails (params) {
  const cellRecords = params.cellRecords
  // console.log('showCellDetails', cellRecords)

  // Show the record in a modal, with link to iOS
  const highlightsContainerElement = document.getElementById('recordHighlights')
  highlightsContainerElement.innerHTML = '' // clean

  const headerElement = document.createElement('h1')
  headerElement.innerText = 'Frass Activity'
  highlightsContainerElement.appendChild(headerElement)

  const highlightGridContainer = document.createElement('div')
  highlightGridContainer.classList.add('cssGrid')

  let rowIndex = 0

  // Headers
  // const columnIndex = 0
  cellRecords.forEach(function (oneRecord, recordIndex) {
    rowIndex++

    let columnIndex = 0
    fields.forEach(function (oneField, fieldIndex) {
      columnIndex++
      const cellElement = document.createElement('div')
      cellElement.style['grid-row'] = rowIndex
      cellElement.style['grid-column'] = columnIndex
      if (oneField.classes) {
        oneField.classes.forEach(function (oneClass) {
          cellElement.classList.add(oneClass)
        })
      }

      if (oneField.processingHtml) {
        const cellHtml = oneField.processingHtml(oneRecord)
        cellElement.appendChild(cellHtml)
      } else {
        const value = oneRecord[oneField.propertyName]
        let formattedValue = value
        if (oneField.processing) {
          formattedValue = oneField.processing(value)
        }
        cellElement.innerHTML = '<p>' + formattedValue + '</p>'
      }

      highlightGridContainer.appendChild(cellElement)
    })
  })
  highlightsContainerElement.append(highlightGridContainer)

  highlightsContainerElement.classList.remove('hide')
}

const fields = [
  {
    propertyName: 'workstationForm',
    processingHtml: function (oneRecord) {
      const urlToRecord = [
        configVar.baseUrlToiOS,
        oneRecord.productionLine,
        oneRecord.workstationForm,
        'edit',
        oneRecord.id
      ].join('/')
      const oneLinkElement = document.createElement('a')
      oneLinkElement.setAttribute('href', urlToRecord)
      oneLinkElement.setAttribute('target', '_blank')
      oneLinkElement.innerText = oneRecord.workstationForm
      return oneLinkElement
    }
  },
  {
    propertyName: 'user_name'
  }
]
