//
// Time to use for the record
export function timestampValue (timestamp) {
  const timestampDate = new Date(timestamp)

  const serverTimezone = -new Date().getTimezoneOffset() / 60

  const timezoneAdjust = serverTimezone // 8
  timestampDate.setHours(timestampDate.getHours() + timezoneAdjust)

  const dateString = timestampDate.toJSON()
  if (dateString === null) return
  const dateValue =
    dateString.substring(0, 10) + ' ' + dateString.substring(11, 19)

  return dateValue
}
