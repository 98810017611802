import * as d3 from 'd3'
import accounting from 'accounting'

import * as configVar from '../../config.js'
import * as helpersCsvExports from '../../helpers/csv/csv-export.js'

const urlApi = [configVar.apiRoutes, 'csv_exports', 'feed-prep'].join('/')

export async function loadAndexportCsv () {
  // console.log('loadAndexportCsv')

  const exportArray = await loadApiData()
  const csvFormatted = d3.csvFormat(exportArray)
  helpersCsvExports.downloadCsv(csvFormatted)
}

export async function feedPrepData () {
  const records = await loadApiData({
    sort: -1,
    forceProperties: ['_id']
  })

  identifyAllProperties({
    array: records
  })

  records.forEach(function (oneRecord, arrayIndex) {
    calculateUsageInfo({
      records,
      arrayIndex
    })
  })

  return records
}

export function findRecordById (params) {
  // console.log('feedRecords', params, feedRecords)
  const record = feedRecords.find(function (oneRecord) {
    return oneRecord.formData._id === params._id
  })
  // console.log('found', record)
  return record
}

// Store in memory
let feedRecords

async function loadApiData (params = {}) {
  // Calling backend
  const fetchOptionsApi = {
    method: 'GET'
  }
  // console.log('urlApi', urlApi)
  return fetch(urlApi, fetchOptionsApi)
    .then(response => response.json())
    .then(function (data) {
      // console.log('api loaded', urlApi)
      // console.log(data)
      const recordValues = data.data

      const recordsData = viewRecords(recordValues)
      sortRecords(recordsData, params)

      feedRecords = recordsData
      // console.log('recordsData', recordsData)

      const properties = identifyAllProperties({
        array: recordsData,
        path: 'formData'
      })
      const propertiesToExport = propertiesForExport(properties, params)

      const exportArray = contentForExport(recordsData, propertiesToExport)
      // console.log('exportArray', exportArray)

      return exportArray
    })
}

//
//
function viewRecords (data) {
  const recordsData = []
  data.forEach(function (oneRecord) {
    // Apply filter if needed
    recordsData.push(oneRecord)
  })

  return recordsData
}

function sortRecords (recordsData, params) {
  const sort = params.sort || 1
  recordsData.sort(function (record1, record2) {
    const date1 = new Date(record1.usableTimestamp)
    const date2 = new Date(record2.usableTimestamp)
    return sort * (date1 - date2)
  })
}

//
//
function identifyAllProperties (params) {
  // console.log('identifyAllProperties', params)
  const arrayData = params.array
  const propertyPath = params.path

  const properties = new Set()
  arrayData.forEach(function (oneRecord) {
    // // console.log(oneRecord)
    let objectVar = oneRecord
    if (propertyPath) {
      objectVar = oneRecord[propertyPath]
    }
    Object.keys(objectVar).forEach(function (oneKey) {
      properties.add(oneKey)
    })
  })
  // console.log('identifyAllProperties', properties)
  return Array.from(properties)
}

function propertiesForExport (properties, params) {
  const propertiesToExport = []

  const forceProperties = params.forceProperties || []

  const exclude = [
    '_id',
    '_rev',
    'processDocument',
    'tenant',
    'device_id',
    'device_ip',
    'description',
    'updatedByCorrectionScript4'
  ]

  // 40: "remarks"
  // 41: "checklistIngredient"

  properties.forEach(function (oneProperty) {
    if (exclude.includes(oneProperty) && !forceProperties.includes(oneProperty)) { return }

    propertiesToExport.push(oneProperty)
  })

  return propertiesToExport
}

function contentForExport (recordsData, properties) {
  const exportArray = []

  recordsData.forEach(function (oneRecord) {
    // // console.log(oneRecord)
    const rowData = {}

    const formValue = oneRecord
    // Base properties: these properties get exported in this declared order in the output csv
    rowData.usableTimestamp = formValue.usableTimestamp
    rowData.productionLine = formValue.productionLine
    rowData.workstationForm = formValue.workstationForm
    rowData.formJoinedId =
      formValue.productionLine + ' :: ' + formValue.workstationForm
    rowData.timestampsDelta = formValue.timestampsDelta
    rowData.user_name = formValue.user_name
    rowData.expectedTrays = formValue.feedPerTray
      ? formValue.totalFeedPrepared / formValue.feedPerTray
      : ''
    rowData.feedPerTray = formValue.feedPerTray
    rowData.totalFeedPrepared = formValue.totalFeedPrepared
    rowData.totalIngredients = '' // Updated later
    rowData.sumIngredientsVsFormTotal = '' // Updated later

    properties.forEach(function (oneProperty) {
      // Add properties if not already set
      if (rowData[oneProperty] === undefined) {
        storeProperty(rowData, formValue, oneProperty)
      }
    })

    rowData.totalIngredients = sumOfIngredients(rowData) // sumOfIngredients(formValue.formData)
    rowData.sumIngredientsVsFormTotal =
      rowData.totalIngredients - rowData.totalFeedPrepared

    exportArray.push(rowData)
  })
  return exportArray
}

function storeProperty (rowData, formValue, oneProperty) {
  if (oneProperty === 'additionalIngredients') {
    const hasProperty = Object.prototype.hasOwnProperty.call(
      formValue.formData,
      oneProperty
    )
    if (!hasProperty) return

    const additionalArray = formValue.formData[oneProperty]
    additionalArray.forEach(function (oneIngredient) {
      const ingredientName = oneIngredient.additionalIngredientsList
      const ingredientValue = +oneIngredient.weightAdditionalIngredientsWeight
      rowData[ingredientName] = ingredientValue
    })
  } else {
    rowData[oneProperty] = Number.isFinite(+formValue.formData[oneProperty])
      ? +formValue.formData[oneProperty]
      : formValue.formData[oneProperty]
  }
  return rowData
}

let count = 5
function sumOfIngredients (formValue) {
  let ingredientSum = 0

  if (count > 0) {
    // console.log('sumOfIngredients', formValue)
  }

  function ingredientValue (property) {
    const val = +formValue[property]
    if (Number.isFinite(val)) {
      return val
    }
    return 0
  }
  ingredientSum += ingredientValue('pkmWeight')
  ingredientSum += ingredientValue('frassWeight')
  ingredientSum += ingredientValue('fineRiceHuskWeight')
  ingredientSum += ingredientValue('exuviaeWeight')

  ingredientSum += ingredientValue('chickenFeedWeight')
  ingredientSum += ingredientValue('chickenFeedActual') // For breeding nursery-feedstock-preparation

  ingredientSum += ingredientValue('plantBasedMilk')
  ingredientSum += ingredientValue('soyWeight')
  ingredientSum += ingredientValue('waterWeight')
  ingredientSum += ingredientValue('waterActual') // breeding nursery-feedstock-preparation

  if (
    formValue.productionLine === 'breeding' &&
    formValue.workstationForm === 'nursery-feedstock-preparation'
  ) {
    ingredientSum += ingredientValue('wheatBranActual')
  } else {
    ingredientSum += ingredientValue('wheatBranSetpoint') // breeding   grow-out-feedstock-preparation
  }

  count--

  return ingredientSum
}

export function analyseProperties (params) {
  const allProperties = identifyAllProperties({
    array: params.array
  })

  const analysedProperties = {
    feed: [],
    nonFeed: [],
    unclassified: []
  }

  allProperties.forEach(function (oneProperty) {
    if (nonFeedProperties.includes(oneProperty)) {
      analysedProperties.nonFeed.push(oneProperty)
      return
    }

    if (feedProperties.includes(oneProperty)) {
      analysedProperties.feed.push(oneProperty)
      return
    }
    analysedProperties.unclassified.push(oneProperty)
  })

  // Sort the properties based on known lists
  analysedProperties.feed.sort(function (a, b) {
    return feedProperties.indexOf(a) - feedProperties.indexOf(b)
  })

  return analysedProperties
}

export const nonFeedProperties = [
  '_id',
  '_rev',
  'description',
  'processDocument',
  'timestamp',
  'usableTimestamp',
  'timestampsDelta',

  'formJoinedId',
  'checklistDidYouDoThis',
  'tenant',
  'type',
  'productionLine',
  'workstationForm',
  'dataRecord',
  'daily_index',
  'device_id',
  'device_ip',
  'user_name',
  'user_id',
  'form_id',
  'form_version',
  'submission_timestamp',
  'remarks',
  'checklistNurseryMixer',
  'checklistCleanMixer',
  'checklistplug',
  'checklistMachine',
  'updatedByCorrectionScript4',
  'breedingGrowOutSetupRackId',
  'edited',
  'checklistIngredient',
  'feedPerTray',

  'undefined',
  'expectedTrays',

  'moistureContent',
  'totalFeedPrepared',
  'additionalIngredients',
  'totalIngredients',
  'sumIngredientsVsFormTotal',

  'c_totalWeight',
  'c_totalSetpoints',
  'c_minFromPrevious',
  'blueprint_version'
]

export const feedProperties = [
  'pkmSetpoint',
  'pkmWeight',
  'pkmActual',

  'wheatBranSetpoint',
  'wheatBranSepoint',
  'wheatBranActual',
  'wheatBranWeight',

  'chickenFeedSetpoint',
  'chickenFeedActual',
  'chickenFeedWeight',

  'fineRiceHuskSetpoint',
  'fineRiceHuskWeight',

  'waterSetpoint',
  'waterSepoint',
  'waterActual',
  'waterWeight',

  'frassSetpoint',
  'frassWeight',
  'feedYeastPowder',
  'fishFeed',
  'exuviaeWeight',
  'cornGluten',
  'fishPallet',
  'soyWeight',
  'plantBasedMilk',
  'fishMeal',
  'wheatFlour',
  'krillMill',
  'hermetProteinWeight'
]

// [isSetpoint, ingredient]
export const feedPropertyDetails = {
  pkmSetpoint: [true, 'pkm'],
  pkmWeight: [false, 'pkm'],
  pkmActual: [false, 'pkm'],
  pkm: [null, 'pkm'],

  fineRiceHuskSetpoint: [true, 'fine rice husk'],
  fineRiceHuskWeight: [false, 'fine rice husk'],
  'fine-rice-husk': [null, 'fine rice husk'], // Material Receiving

  wheatBranSetpoint: [true, 'wheat bran'],
  wheatBranSepoint: [true, 'wheat bran'],
  wheatBranActual: [false, 'wheat bran'],
  wheatBranWeight: [false, 'wheat bran'],
  'wheat-bran': [null, 'wheat bran'],

  chickenFeedSetpoint: [true, 'chicken feed'],
  chickenFeedActual: [false, 'chicken feed'],
  chickenFeedWeight: [false, 'chicken feed'],
  'chicken-feed': [null, 'chicken feed'], // Material Receiving

  frassSetpoint: [true, 'frass'],
  frassWeight: [false, 'frass'],

  maturedFrassSetpoint: [true, 'frass matured'],
  maturedFrass: [false, 'frass matured'],

  waterSetpoint: [true, 'water'],
  waterSepoint: [true, 'water'],
  waterActual: [false, 'water'],
  waterWeight: [false, 'water'],

  cornGluten: [false, 'corn gluten'],
  'corn-gluten': [null, 'frass'],

  wheatFlour: [false, 'wheat flour'],
  'wheat-flour': [null, 'wheat flour'],

  krillMill: [false, 'krill meal'],
  'krill-meal': [null, 'krill meal'],

  fishMeal: [false, 'fish meal'],
  fishmeal: [null, 'fish meal'],

  fishFeed: [false, 'fish feed'],
  'fish-feed': [null, 'fish feed'],

  fishPallet: [false, 'fish pallet'],
  'fish-pallet': [null, 'fish pallet'],

  exuviaeWeight: [false, 'exuvia']

  // 'feedYeastPowder',
  // 'soyWeight',
  // 'plantBasedMilk',
  // 'hermetProteinWeight'
}
// // console.log('feedPropertyDetails', feedPropertyDetails)

export function isSetpoint (oneFeedProperty) {
  const propertyDetailedInfo = feedPropertyDetails[oneFeedProperty]
  if (propertyDetailedInfo) {
    if (propertyDetailedInfo[0]) {
      return true
    }
  }

  if (oneFeedProperty?.toLowerCase().includes('setpoint')) return true
}

export function calculateUsageInfo (params) {
  // // console.log('calculateUsageInfo', params)
  const records = params.records
  if (!records) return

  const arrayIndex = params.arrayIndex
  const record = records[arrayIndex]
  // // console.log('calculateUsageInfo', record)
  // // console.log('feedPropertyDetails', feedPropertyDetails)
  record.c_totalWeight = 0
  record.c_totalSetpoints = 0

  Object.keys(record).forEach(function (recordProperty) {
    // Ensure value is not marked as not being feed
    if (nonFeedProperties.includes(recordProperty)) return

    // Ensure the value is a number
    const propertyWeight = +record[recordProperty]
    if (!Number.isFinite(propertyWeight)) return

    if (isSetpoint(recordProperty)) {
      record.c_totalSetpoints += propertyWeight
      return
    }

    record.c_totalWeight += propertyWeight
  })

  // time from previous records
  const previousRecord = records[arrayIndex + 1]
  if (!previousRecord) return
  const recordTime = new Date(record.usableTimestamp)
  const previousTime = new Date(previousRecord.usableTimestamp)
  const minutesFromPrevious = (recordTime - previousTime) / (1000 * 60)
  record.c_minFromPrevious = [
    accounting.formatNumber(minutesFromPrevious, 0, ',', '.'),
    'min'
  ].join('')
}
/*
Brainstorming grouping of feed properties
... sum the setPoints or actuals together
pkm: {
  properties: [
    {
      'pkmSetpoint': 'setpoint',
      'pkmWeight': 'actual',
      'pkmActual': 'actual
    }
  ]
},
wheatbran: {
  properties: [
    'wheatBranSetpoint': 'setpoint',
    'wheatBranSepoint': 'setpoint'
    'wheatBranActual': 'actual'
    'wheatBranWeight': 'actual'
  ]
}

chickenFeedSetpoint: {
  [farming:feed]: 'setPoint'
  [breeding:feed]: 'actual'
}
*/

/*
... List all properties to find out which are feed
... properties might vary depending on form and time

  Ingredients

  totalFeedPrepared

  PKM
    pkmSetpoint
    pkmActual
    pkmWeight

    pkmWeight
breeding
grow-out-feedstock-preparation

  Wheat Bran
    wheatBranSetpoint
    wheatBranActual
    wheatBranWeight

  Fine Rice Husk
    fineRiceHuskWeight

  Chicken Feed
    chickenFeedSetpoint
    chickenFeedActual
    chickenFeedWeight

  Water
    waterSetpoint
    waterActual
    waterWeight

  frassWeight

*/

/*
30: "feedPerTray"
33: "soyWeight"
46: "plantBasedMilk"
*/
